import React, { Component } from 'react';
import axios from 'axios';

class AdresseComponent extends Component {
    state = {
        showContainer: false,
        search: "",
        result_search: [],
        getDataTimeout: null
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;

        if (prevProps.value !== value) {
            this.setState({ search: Object.keys(value).length === 0 ? "" : value.value })
        }
    }

    nameChangeHandler = (event) => {
        const { value } = event.target;
        this.setState({ search: value });
        clearTimeout(this.state.getDataTimeout);
        this.setState({ getDataTimeout: setTimeout(this.handleSearchValue, 1000) });
    };

    onSearchApi = (value) => {
        if (value !== "") {
            axios.get(`${process.env.REACT_APP_URL_ENTALAB_ADRESSE_API}/search?q=${value}&type=housenumber`)
                .then((res) => {
                    this.setState({ result_search: res.data.features })
                })
                .catch((e) => console.log("Error : ", e));
        }
    }

    onBlur = (e) => {
        const currentTarget = e.currentTarget;
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                this.toggleSearch(false);
            }
        }, 0);
    }

    toggleSearch = (bool, e) => {
        const { onBlur } = this.props;
        if (!bool && onBlur) {
            this.onBlur();
        }

        if (bool && (this.state.search).length > 0) {
            this.onSearchApi(this.state.search);
        }

        this.setState({
            showContainer: bool
        });
    }

    handleSearchValue = () => {
        const { isHospitals } = this.props;
        const { search } = this.state;

        if (!isHospitals) {
            this.onSearchApi(search);
        }
    }

    handleSelectAutoComplete = (value, departement = null, city = null) => {
        const { hospitals, onHandleValue } = this.props;
        var autocomplete = {
            value,
            departement: departement ? departement : null,
            city: city ? city : null,
            isHospitals: hospitals.includes(value) ? true : false
        }

        this.setState({ search: value })
        onHandleValue(autocomplete);
        this.toggleSearch(false);
    }

    render() {
        const { showContainer } = this.state;
        const { placeholder, type, icon, hospitals, /*value,*/ isHospitals, options } = this.props;

        return (
            <div onBlur={(e) => this.onBlur(e)} className={`form-input pr ${options || ""}`}>
                {icon}
                <input placeholder={placeholder} value={this.state.search} type={type || "text"} onChange={this.nameChangeHandler} onFocus={(e) => this.toggleSearch(true, e)} readOnly={hospitals} />
                {showContainer &&
                    <div className="autocomplete">
                        {hospitals ?
                            <>
                                <div className="flex mb-5 of-x-auto items-autocomplete">
                                    {
                                        hospitals.map((item) =>
                                            <button key={item} onMouseDown={() => this.handleSelectAutoComplete(item, null)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="46" width="46" viewBox="0 0 640 512">
                                                    <path opacity="1" d="M192 48c0-26.5 21.5-48 48-48H400c26.5 0 48 21.5 48 48V512H368V432c0-26.5-21.5-48-48-48s-48 21.5-48 48v80H192V48zM48 96H160V512H48c-26.5 0-48-21.5-48-48V320H80c8.8 0 16-7.2 16-16s-7.2-16-16-16H0V224H80c8.8 0 16-7.2 16-16s-7.2-16-16-16H0V144c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v48H560c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v64H560c-8.8 0-16 7.2-16 16s7.2 16 16 16h80V464c0 26.5-21.5 48-48 48H480V96H592zM312 64c-8.8 0-16 7.2-16 16v24H272c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V152h24c8.8 0 16-7.2 16-16V120c0-8.8-7.2-16-16-16H344V80c0-8.8-7.2-16-16-16H312z" />
                                                </svg>
                                                <p>{item}</p>
                                            </button>
                                        )
                                    }
                                </div>
                                {
                                    (hospitals.sort(() => Math.random() - 0.5).slice(0, 5)).map((item, index) =>
                                        <button className="item-autocomplete" key={index} onMouseDown={() => this.handleSelectAutoComplete(item, null)}>
                                            <div className="flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="hw-25" style={{ margin: "auto 0", marginRight: "10px" }}>
                                                    <path opacity="1" d="M192 48c0-26.5 21.5-48 48-48H400c26.5 0 48 21.5 48 48V512H368V432c0-26.5-21.5-48-48-48s-48 21.5-48 48v80H192V48zM48 96H160V512H48c-26.5 0-48-21.5-48-48V320H80c8.8 0 16-7.2 16-16s-7.2-16-16-16H0V224H80c8.8 0 16-7.2 16-16s-7.2-16-16-16H0V144c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v48H560c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v64H560c-8.8 0-16 7.2-16 16s7.2 16 16 16h80V464c0 26.5-21.5 48-48 48H480V96H592zM312 64c-8.8 0-16 7.2-16 16v24H272c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V152h24c8.8 0 16-7.2 16-16V120c0-8.8-7.2-16-16-16H344V80c0-8.8-7.2-16-16-16H312z" />
                                                </svg>
                                                <div className="flex aic">
                                                    {item}
                                                </div>
                                            </div>
                                        </button>
                                    )
                                }
                            </>
                            : null
                        }
                        {!isHospitals ?
                            this.state.search !== "" && this.state.result_search.length > 0 ?
                                this.state.result_search.map((item, index) =>
                                    <button className="item-autocomplete" key={index} onMouseDown={() => this.handleSelectAutoComplete(item.properties.label, item.properties.context, item.properties.city)}>
                                        <div className="flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="hw-25" style={{ margin: "auto 0", marginRight: "10px" }}>
                                                <path d="M39.5,43h-9c-1.381,0-2.5-1.119-2.5-2.5v-9c0-1.105-0.895-2-2-2h-4c-1.105,0-2,0.895-2,2v9c0,1.381-1.119,2.5-2.5,2.5h-9 C7.119,43,6,41.881,6,40.5V21.413c0-2.299,1.054-4.471,2.859-5.893L23.071,4.321c0.545-0.428,1.313-0.428,1.857,0L39.142,15.52 C40.947,16.942,42,19.113,42,21.411V40.5C42,41.881,40.881,43,39.5,43z" />
                                            </svg>
                                            <div>
                                                <div>{item.properties.label}</div>
                                                <div style={{ fontWeight: "normal", fontSize: "14px" }}>{item.properties.context}</div>
                                            </div>
                                        </div>
                                    </button>
                                )
                                :
                                <p className={hospitals ? "mt-10" : ""} style={{ textAlign: "center", fontFamily: "Segoe UI SemiBold" }}>Resultat introuvable</p>
                            :
                            null
                        }
                    </div>
                }
            </div>
        );
    }
}

export default AdresseComponent;