import React, { Component } from 'react';

class InputIconComponent extends Component {
    handleKeyDown = (event) => {
        const { nextInput } = this.props;

        if(typeof nextInput === "function") {
            if (event.key === 'Enter') {
                event.preventDefault();
                
                nextInput();
            }
        }
    }

    render() {
        const { placeholder, type, value, focus, onHandleChange, icon, disabled, options } = this.props;

        return (
            <div className={`form-input pr ${options || ""}`}>
                {icon}
                <input placeholder={placeholder} type={type || "text"} value={value} onFocus={ focus == "date" ? (e) => (e.target.type = 'date') : null } style={disabled == "disabled" ? { color: "#6d6d6d" } : {}} disabled={disabled || ""} autoComplete={"off"} onKeyDown={this.handleKeyDown} onChange={onHandleChange} enterkeyhint="go"/>
            </div>
        );
    }
}

export default InputIconComponent;