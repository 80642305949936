import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import InputIconComponent from './components/InputIconComponent';

import axios from "axios";

import star_life_left from '../asserts/img/star_life-left.webp';
import star_life_right from '../asserts/img/star_life-right.webp';

class EmailVerify extends Component {
    state = {
        error_message: "",
        message: "",
        email: "",
        forgot_password: false,
    };

    componentDidMount() {
        this.keyDownHandler = this.keyDownHandler.bind(this);
        document.addEventListener('keydown', this.keyDownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownHandler);
    }

    onCheckEmail(email) {
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (filter.test(email)) {
            return true
        } else {
            return false
        }
    }

    keyDownHandler(event) {
        if (event.key === 'Enter') {
            if(!this.state.forgot_password) {
                event.preventDefault();
                this.validateAccount();
            }
        }
    }

    validateAccount = async () => {
        this.setState({
            error_message: "",
            message: "",
            connecting: true,
            forgot_password: false
        });

        if (this.onCheckEmail(this.state.email)) {
            const credentials = {
                email: this.state.email
            };

            axios.post(`${process.env.REACT_APP_API}/api/v1/account/forget/password`, credentials).then(async (res) => {
                if(res.data.success == 1){
                    this.setState({
                        message: "Email envoyé avec succès (Vérifie tes mails)",
                        connecting: false,
                        forgot_password: true
                    }, () => {
                        setTimeout(() => {
                            this.props.history.push("/");
                        }, 3000);
                    });
                } else if(res.data.success == 2) {
                    this.setState({
                        error_message: "Email inconnu",
                        connecting: false
                    });
                } else {
                    this.setState({
                        error_message: "Veuillez réessayer plus tard",
                        connecting: false
                    });
                }
            })
            .catch(() => {
                this.setState({ 
                    error_message: "Veuillez réessayer plus tard",
                    connecting: false
                });
            });
        } else {
            this.setState({
                error_message: "Email invalide",
                connecting: false
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent />
                <img className="starlife_backstage starlife_left" alt='starlife_backstage-left' src={star_life_left} style={{ marginTop: "80px" }} />
                <img className="starlife_backstage starlife_right"  alt='starlife_backstage-right' src={star_life_right} />

                <div className="connection_container">
                    <div className="signin-container">
                        <h1 className="signin_title mb-10" style={{ fontSize: "xx-large" }}>Mot de passe oublié</h1>
                        <p className="signin_subtitle mb-20">Entrez l'adresse email associé à votre compte <span style={{ color: "var(--color-primary)", fontFamily: "Arial Bold" }}>{ process.env.REACT_APP_APP_NAME }</span> pour modifier votre mot de passe.</p>
                        <div>
                            
                            {
                                this.state.error_message.length > 0 ?
                                    <div style={{ 
                                        textAlign: "center", 
                                        padding: "12px", 
                                        background: "#ffb5b5", 
                                        color: "red", 
                                        fontFamily: "Arial", 
                                        marginBottom: "10px" 
                                    }}>
                                        {this.state.error_message}
                                    </div>
                                :
                                null
                            }
                            { 
                                this.state.message.length > 0  ? 
                                    <div style={{ 
                                        textAlign: "center", 
                                        padding: "12px", 
                                        background: "#b5ffb5", 
                                        color: "green", 
                                        fontFamily: "Arial", 
                                        marginBottom: "10px" 
                                    }}>
                                        {this.state.message}
                                    </div> 
                                :
                                null
                            }
                        </div>
                        { 
                            !this.state.forgot_password ?
                                <div>
                                    <InputIconComponent placeholder="E-mail" type="email" value={this.state.email} onHandleChange={(e) => this.setState({ email: e.target.value })} icon={<svg xmlns="http://www.w3.org/2000/svg" className="hw-25" viewBox="0 0 34.875 34.875"><path d="M17.438.563A17.438,17.438,0,1,0,34.875,18,17.434,17.434,0,0,0,17.438.563Zm0,6.75A6.188,6.188,0,1,1,11.25,13.5,6.188,6.188,0,0,1,17.438,7.313Zm0,24.188a13.474,13.474,0,0,1-10.3-4.8,7.839,7.839,0,0,1,6.926-4.2,1.72,1.72,0,0,1,.5.077,9.309,9.309,0,0,0,2.876.485,9.274,9.274,0,0,0,2.876-.485,1.72,1.72,0,0,1,.5-.077,7.839,7.839,0,0,1,6.926,4.2A13.474,13.474,0,0,1,17.438,31.5Z" transform="translate(0 -0.563)"/></svg>} options="mt-10" />
                                    <button className="search_button" style={this.state.connecting ? { fontSize: "17px", padding: "13.3px 0px" } : { fontSize: "17px" }} onClick={this.state.connecting ? null : this.validateAccount}>{ this.state.connecting ? <div className="loader"></div> : "Réinitialiser le mot de passe" }</button>
                                </div>
                            :
                            <NavLink to="/signin" style={{ textDecoration: "none" }}>
                                <button className="search_button" style={{ fontSize: "17px" }}>Se connecter</button>
                            </NavLink>
                        }
                        <p className="create">Deja un compte ? <NavLink style={{ textDecoration: "none", color: "var(--color-primary)", fontFamily: "Arial Bold" }} to="/signin">Se connecter</NavLink></p>
                    </div>
                </div>
                <FooterComponent />
            </>
        );
    }
}

export default EmailVerify;