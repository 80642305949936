import React, { Component } from 'react';

import { getToken } from './utilities/AccountManager';

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import RadioComponent from './components/RadioComponent';
import InputIconComponent from './components/InputIconComponent';
import Pagination from './components/Pagination';
import staroflife from '../asserts/img/staroflife.webp';
import profile from '../asserts/img/profile.webp';

import axios from "axios";
import dayjs from 'dayjs';
import Modal from 'react-modal';

import "react-day-picker/dist/style.css";

import star_life_left from '../asserts/img/star_life-left.webp';
import star_life_right from '../asserts/img/star_life-right.webp';

const ModalHomeStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "transparent",
        border: "none",
        width: "100%",
        margin: "auto",
        overflow: "unset"
    },
    overlay : {
        backgroundColor: "rgba(172, 172, 172, 0.41)",
        zIndex: '5'
    }
};

class AdministrationUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            totalAccounts: 0,
            currentPage: 1,
            limit: 20,
            roles: [
                {
                    id: "employee",
                    label: "Employer"
                },
                {
                    id: "user",
                    label: "Utilisateur"
                }
            ],
            genders: [
                {
                    id: "MALE",
                    label: "HOMME"
                },
                {
                    id: "FEMALE",
                    label: "FEMME"
                }
            ],
            role: "employee",
            account_data: {},
            search: "",
            information_account: false,
            create_account: false,
            connecting: false,
            gender: "MALE",
            firstname: "",
            lastname: "",
            birthdate: "",
            email: "",
            password: "",
            error_message: "",
            error_number: false,
            accountRole: [
                "USER"
            ],
            createAccount: false,
            updateAccount: false
        };
    }

    async componentDidMount() {
        try {
            if (localStorage.getItem("login") == true && localStorage.getItem("token") != null) { 
                const token = await getToken();

                try {
                    if (token) {
                        const userinfosToken = token.split(".")[1];
                        const userinfos = JSON.parse(atob(userinfosToken));

                        if ((userinfos.roles).includes("BOSS")) {
                            this.keyDownHandler = this.keyDownHandler.bind(this);
                            document.addEventListener('keydown', this.keyDownHandler);

                            this.requestOnAccounts();
                            this.setState({ userinfos: userinfos });
                        } else {
                            this.props.history.push("/");
                        }
                    }
                } catch (error) { 
                    this.props.history.push("/");
                }
            } else {
                this.props.history.push("/");
            }
        } catch (error) {
            this.props.history.push("/");
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownHandler);
    }
    
    requestOnAccounts = async (limit = null) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API}/api/v1/administration/get/users/${this.state.offset || 0}`,
                params: {
                    type: this.state.role,
                    search: this.state.search,
                    limit: (limit == null ? this.state.limit : limit) || 20
                },
                headers: { 'Authorization': `Bearer ${await getToken()}` }
            });
            
            const { accounts, totalCount } = response.data;
    
            this.setState({ 
                accounts: accounts,
                totalAccounts: totalCount
            });
        } catch (error) {
            console.log('Erreur côté client :', error)
        }
    }

    refresh = async () => {
        await this.requestOnAccounts();
    }

    onChangeType = async (e) => {
        this.setState({ role: e.target.value }, async () => {
            await this.requestOnAccounts();
        });
    }

    onChangeLimit = async (e) => {
        this.setState({ limit: e.target.value }, async () => {
            await this.requestOnAccounts();
        });
    }

    onChangeSearch = async (e) => {
        this.setState({ search: e.target.value }, async () => {
            await this.requestOnAccounts();
        })
    }

    onInformationsAccount = async (id) => {
        const account = this.state.accounts.find(res => res._id === id);
      
        if (!account) {
            console.error(`No account found with id ${id}`);
            return;
        }

        await this.setState({ account_data: account }, await this.openCancelAccount);
    }

    openCancelAccount = () => {
        this.setState({ information_account: !this.state.information_account });
    }

    calculateAge(birthdate) {
        const dateNaissanceObj = dayjs(birthdate);
        const dateActuelle = dayjs();

        const differenceAnnees = dateActuelle.diff(dateNaissanceObj, 'year');
        return differenceAnnees;
    }

    updateRoles = (newRoles) => {
        if((this.state.account_data.roles).includes((newRoles.target.value))) {
            //retir
            const updatedRoles = (this.state.account_data.roles).filter(role => role !== newRoles.target.value);
            this.setState((prevState) => ({
                account_data: {
                    ...prevState.account_data,
                    roles: updatedRoles,
                },
            }));
        } else {
            const updatedRoles = [...this.state.account_data.roles, newRoles.target.value];
            this.setState((prevState) => ({
                account_data: {
                    ...prevState.account_data,
                    roles: updatedRoles,
                },
            }));
        }
    };

    updateFirstName = (firstname) => {
        this.setState((prevState) => ({
            account_data: {
                ...prevState.account_data,
                firstname: firstname.target.value,
            },
        }));
    };

    updateLastName = (lastname) => {
        this.setState((prevState) => ({
            account_data: {
                ...prevState.account_data,
                lastname: lastname.target.value,
            },
        }));
    };

    updateEmail = (email) => {
        this.setState((prevState) => ({
            account_data: {
                ...prevState.account_data,
                email: email.target.value,
            },
        }));
    };

    updateBirthdate = (birthdate) => {
        this.setState((prevState) => ({
            account_data: {
                ...prevState.account_data,
                birthdate: birthdate.target.value,
            },
        }));
    };

    onClickUpdateInformations = async () => {
        this.setState({
            updateAccount: true,
        })

        const updatedAccounts = [...this.state.accounts];
        const indexToUpdate = updatedAccounts.findIndex((account) => account._id === this.state.account_data._id);
    
        if (indexToUpdate !== -1) {
            const currentAccount = updatedAccounts[indexToUpdate];
            const updatedFields = {};
    
            // Comparer les valeurs actuelles avec celles du formulaire
            if (currentAccount.firstname !== this.state.account_data.firstname) {
                updatedFields.firstname = this.state.account_data.firstname;
            }
    
            if (currentAccount.lastname !== this.state.account_data.lastname) {
                updatedFields.lastname = this.state.account_data.lastname;
            }
    
            if (currentAccount.email !== this.state.account_data.email) {
                updatedFields.email = this.state.account_data.email;
            }
    
            if (currentAccount.birthdate !== this.state.account_data.birthdate) {
                updatedFields.birthdate = this.state.account_data.birthdate;
            }
    
            if (!this.arraysEqual(currentAccount.roles, this.state.account_data.roles)) {
                updatedFields.roles = this.state.account_data.roles;
            }
    
            if (Object.keys(updatedFields).length > 0) {
                try {
                    const response = await axios({
                        method: 'POST',
                        url: `${process.env.REACT_APP_API}/api/v1/administration/update/user/${this.state.account_data._id}`,
                        data: updatedFields,
                        headers: { 'Authorization': `Bearer ${await getToken()}` }
                    });
    
                    if (response.data.success === 1) {
                        this.openCancelAccount();
                        updatedAccounts[indexToUpdate] = this.state.account_data;
    
                        this.setState({
                            updateAccount: false,
                            accounts: updatedAccounts
                        }, () => {
                            console.log('Compte mis à jour dans le tableau et l\'état a été mis à jour :', this.state.accounts);
                        });
                    } else {
                        this.setState({
                            updateAccount: false,
                        })
                        console.error('Erreur côté client :', response.data);
                    }
                } catch (error) {
                    this.setState({
                        updateAccount: false,
                    })
                    console.error('Erreur côté client :', error);
                }
            } else {
                this.setState({
                    updateAccount: false,
                })
                console.log('Aucun champ mis à jour.');
            }
        } else {
            this.setState({
                updateAccount: false,
            })
            console.log('Compte non trouvé dans le tableau.');
        }
    };
    
    arraysEqual = (arr1, arr2) => {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    openCreateAccount = () => {
        this.setState({ 
            create_account: !this.state.create_account,
            gender: "MALE",
            firstname: "",
            lastname: "",
            email: "",
            error_message: "",
            password: "",
            birthdate: "",
            accountRole: [
                "USER"
            ]
        });
    }

    onCheckEmail = (email) => {
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (filter.test(email)) {
            return true
        } else {
            return false
        }
    }

    onClickCreateAccount = async () => {
        this.setState({
            createAccount: true,
            error_message: ""
        });

        if(!(["MALE", "FEMALE"].includes(this.state.gender)) || this.state.firstname == "" || this.state.lastname == "" || this.state.email == "" || this.state.password == "" || this.state.birthdate == "" || !(["USER", "EMPLOYEE", "BOSS"].some(role => (this.state.accountRole).includes(role))) ) {
            this.setState({
                createAccount: false,
                error_message: "Veuillez remplir tous les champs"
            });
        }else{
            if(this.onCheckEmail(this.state.email)) {
                try {
                    const response = await axios({
                        method: 'POST',
                        url: `${process.env.REACT_APP_API}/api/v1/administration/create/users/`,
                        data: {
                            email: this.state.email,
                            password: this.state.password,
                            firstname: this.state.firstname,
                            lastname: this.state.lastname,
                            birthdate: this.state.birthdate,
                            roles: this.state.accountRole,
                            gender: this.state.gender
                        },
                        headers: { 'Authorization': `Bearer ${await getToken()}` }
                    });

                    const { success, error } = response.data;

                    if(success == 1) {
                        this.setState({ 
                            createAccount: false, 
                            create_account: false,
                            gender: "MALE",
                            firstname: "",
                            lastname: "",
                            email: "",
                            password: "",
                            birthdate: "",
                            accountRole: [
                                "USER"
                            ]
                        }, async () => {
                            await this.requestOnAccounts();
                        });
                    } else {
                        this.setState({ 
                            createAccount: false,
                            error_message: error
                        });
                    }
                } catch (error) {
                    this.setState({ 
                        createAccount: false, 
                        error_message: error
                    });
                }
            } else {
                this.setState({
                    createAccount: false,
                    error_message: "Veuillez entrer un email valide"
                });
            }
        }
    }

    keyDownHandler(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            if(this.state.create_account) {
                this.onClickCreateAccount();
            } else if(this.state.information_account) {
                this.onClickUpdateInformations();
            }
        }
    }

    render() {
        const accounts = (this.state.accounts).map((account, index) => {
            return (
                <div className="flex jcsb" style={{ borderBottom: "1px solid var(--color-gray)", padding: "5px 0px" }} key={index}>
                    <div className="flex aic" style={{ padding: "10px 0px" }}>
                        <div className="flex">
                            { this.state.role === "employee" ?
                                <img src={staroflife} alt="staroflife" class="hw-45 mr-10" />
                                :
                                <div class="profile-image">
                                    <img src={profile} alt="profile_picture" />
                                </div>
                            }
                            <div>
                                <p style={{ fontFamily: "Arial Rounded MT", fontWeight: "bold", fontSize: "16px", marginBottom: "5px", color: "var(--color-primary)" }}>{account.firstname} {account.lastname}</p>
                                <p style={{ fontFamily: "Arial Rounded MT", fontSize: "16px" }}>{ account.gender == "MALE" ? "Homme" : "Femme" } - {this.calculateAge(account.birthdate)} ans</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid aic">
                        <button onClick={() => this.onInformationsAccount(account._id)} style={{ color: "var(--color-primary)", padding: "5px", fontFamily: "Segoe UI SemiBold", cursor: "pointer" }}>Gerer le compte</button>
                    </div>
                </div>
            )
        })

        return (
            <>
                <HeaderComponent />
                <img className="starlife_backstage starlife_left" alt='starlife_backstage-left' src={star_life_left} style={{ marginTop: "80px" }} />
                <img className="starlife_backstage starlife_right" alt='starlife_backstage-right' src={star_life_right} />

                <div className="faq_container">
                    <div className="flex jcsb mb-10">
                        <p className="title_category">Rechercher { this.state.role === "employee" ? "Ambulancier" : "Patient" }</p>
                        <div className="flex">
                            <button className="ml-4" onClick={this.openCreateAccount} style={{ color: "black" }}>Nouveau Compte</button>
                        </div>
                    </div>
                    <div className="search_users"> 
                        <div style={{ width: "fit-content" }}>
                            <RadioComponent types={this.state.roles} selected={this.state.role} nameRadio="radio_type_roles" options="br-black" onHandleTypes={this.onChangeType}/>
                        </div>
                        <div>
                            <InputIconComponent placeholder="Recherche" type="search" options="br-black" value={this.state.search} onHandleChange={this.onChangeSearch}/>
                        </div>
                    </div>
                    <div className="flex jcsb" style={{ fontFamily: "Roboto Bold", marginBottom: "15px" }}>
                        <div className="flex aic">
                            <span>Resultats ({this.state.totalAccounts || 0})</span>
                            <svg className="hw-16" style={{ cursor: "pointer", marginLeft: "5px" }} onClick={this.refresh} viewBox="0 0 118.04 122.88">
                                <path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z"/>
                            </svg>
                        </div>
                        <select style={{ fontSize: "14px", padding: "0px", width: "unset" }} value={this.state.limit} onChange={this.onChangeLimit}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={25}>25</option>
                        </select>
                    </div>
                    { (this.state.accounts).length != 0 ?
                        <>
                            <div style={{ maxHeight: "405px", height: "405px", overflow: "auto" }}>
                                {accounts}
                            </div>
                            <Pagination
                                className="pagination-bar"
                                currentPage={this.state.currentPage}
                                totalCount={this.state.totalAccounts}
                                pageSize={this.state.limit}
                                onPageChange={(page) => this.setState({ currentPage: page, offset: (page - 1) * this.state.limit }, () => this.requestOnAccounts())}
                            />
                        </>
                        :
                        <p className="text-center">Aucune reservations</p>
                    }
                    <Modal isOpen={this.state.create_account} onRequestClose={this.openCreateAccount} style={ModalHomeStyles}>
                        <div className="modal-card nj abs-c">
                            <div className="pr">
                                <h2 className="mb-20" style={{ color: "var(--color-primary)", fontFamily: "Lemon Milk Pro Medium", paddingRight: "40px" }}>
                                    Créer de compte
                                </h2>
                                <button onClick={this.openCreateAccount} style={{ position: "absolute", left: "97%", transform: "translate(-50%, -50%)", top: "18px", padding: "7px", borderRadius: "13px", background: "#0000BB14", height: "40px", width: "40px", justifyContent: "center", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <svg version="1.1" viewBox="0 0 512 512" style={{ height: "17px",width: "17px",fill: "var(--color-primary)" }}>
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="maxh-modal">
                                <div className="flex aic jcc" style={{ padding: "10px 0px" }}>
                                    <div className="flex">
                                        <div class="profile-image">
                                            <img src={profile} alt="profile_picture" />
                                        </div>
                                        <div>
                                            <p style={{ fontFamily: "Arial Rounded MT", fontWeight: "bold", fontSize: "16px", marginBottom: "5px", color: "var(--color-primary)" }}>{this.state.firstname || "Aucun"} {this.state.lastname || "Aucun"}</p>
                                            <p style={{ fontFamily: "Arial Rounded MT", fontSize: "16px" }}>{ this.state.gender == "MALE" ? "Homme" : "Femme" } - {this.calculateAge(this.state.birthdate)} ans</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "min-content", margin: "auto" }}>
                                    <RadioComponent types={this.state.genders} selected={this.state.gender} nameRadio="genre" onHandleTypes={(e) => this.setState({ gender: e.target.value })}/>
                                </div>
                                { this.state.error_message != "" ?
                                    <div className="mt-10" style={{ textAlign: "center", color: "red", background: "#ff00003b", padding: "10px" }}>{this.state.error_message}</div>
                                    :
                                    null
                                }
                                <div className="flex" style={{ gap: "10px" }}> 
                                    <InputIconComponent placeholder="Prénom" type="firstname" options="mt-10 w-100p" value={this.state.firstname} onHandleChange={(e) => this.setState({ firstname: e.target.value })}/>
                                    <InputIconComponent placeholder="Nom de famille" type="lastname" options="mt-10 w-100p" value={this.state.lastname} onHandleChange={(e) => this.setState({ lastname: e.target.value })}/>
                                </div>
                                <InputIconComponent placeholder="E-mail" type="email" value={this.state.email} onHandleChange={(e) => this.setState({ email: e.target.value })} icon={<svg xmlns="http://www.w3.org/2000/svg" className="hw-25" viewBox="0 0 34.875 34.875"><path d="M17.438.563A17.438,17.438,0,1,0,34.875,18,17.434,17.434,0,0,0,17.438.563Zm0,6.75A6.188,6.188,0,1,1,11.25,13.5,6.188,6.188,0,0,1,17.438,7.313Zm0,24.188a13.474,13.474,0,0,1-10.3-4.8,7.839,7.839,0,0,1,6.926-4.2,1.72,1.72,0,0,1,.5.077,9.309,9.309,0,0,0,2.876.485,9.274,9.274,0,0,0,2.876-.485,1.72,1.72,0,0,1,.5-.077,7.839,7.839,0,0,1,6.926,4.2A13.474,13.474,0,0,1,17.438,31.5Z" transform="translate(0 -0.563)"/></svg>} options="mt-10" />
                                <InputIconComponent placeholder="Password" type="password" value={this.state.password} onHandleChange={(e) => this.setState({ password: e.target.value })} icon={<svg xmlns="http://www.w3.org/2000/svg" className="hw-25" viewBox="0 0 512 512"><path opacity="1" d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z"/></svg>} options="mt-10" />
                                <InputIconComponent placeholder="Date de naissance" type="date" value={dayjs(this.state.birthdate).format("YYYY-MM-DD")}  onHandleChange={(e) => this.setState({ birthdate: e.target.value })} icon={<svg className="hw-25" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"/> <line x1="16" y1="2" x2="16" y2="6"/> <line x1="8" y1="2" x2="8" y2="6"/> <line x1="3" y1="10" x2="21" y2="10"/></svg>} options="mt-10" />
                                <fieldset className='mt-10' style={{ border: "3px solid var(--color-primary)", fontFamily: "Arial" }}>
                                    <legend style={{ marginLeft: "5px", padding: "0px 7px", fontFamily: "Arial Rounded MT", fontWeight: "bold", fontSize: "16px", color: "var(--color-primary)" }}>Roles</legend>
                                    <div className="flex jcsb" style={{ padding: "10px", gap: "10px" }}>
                                        { 
                                            ([
                                                { name: "USER", label: "Utilisateur" }, 
                                                { name: "EMPLOYEE", label: "Employer" }, 
                                                { name: "BOSS", label: "Patron" }
                                            ]).map((role, index) => {
                                                return (
                                                    <div class="tile" key={index}>
                                                        <input type="checkbox" name={role.name} id={role.name} value={role.name} checked={(this.state.accountRole).includes(role.name)} onChange={() => role.name === "USER" ? null : (this.state.accountRole).includes((role.name)) ? this.setState({ accountRole: (this.state.accountRole).filter(value => value !== role.name) }) : this.setState({ accountRole: [...this.state.accountRole, role.name] })} />
                                                        <label className='label_custom' for={role.name}>
                                                            { role.name === "EMPLOYEE" || role.name === "BOSS" ?
                                                                <img src={staroflife} alt="staroflife" class="hw-45" />
                                                                :
                                                                <div class="profile-image" style={{ margin: 0 }}>
                                                                    <img src={profile} alt="profile_picture" />
                                                                </div>
                                                            }
                                                            <h6>{role.label}</h6>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </fieldset>
                                <button className="search_button" style={this.state.createAccount ? { fontSize: "17px", padding: "13.3px 0px" } : { fontSize: "17px" }} onClick={this.state.createAccount ? null : this.onClickCreateAccount}>{ this.state.createAccount ? <div className="loader"></div> : "Créer le compte" }</button>
                            </div>
                        </div>
                    </Modal>
                    <Modal isOpen={this.state.information_account} onRequestClose={this.openCancelAccount} style={ModalHomeStyles}>
                        <div className="modal-card nj abs-c">
                            <div className="pr">
                                <h2 className="mb-20" style={{ color: "var(--color-primary)", fontFamily: "Lemon Milk Pro Medium", paddingRight: "40px" }}>
                                    Information de compte
                                </h2>
                                <button onClick={this.openCancelAccount} style={{ position: "absolute", left: "97%", transform: "translate(-50%, -50%)", top: "18px", padding: "7px", borderRadius: "13px", background: "#0000BB14", height: "40px", width: "40px", justifyContent: "center", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <svg version="1.1" viewBox="0 0 512 512" style={{ height: "17px",width: "17px",fill: "var(--color-primary)" }}>
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="maxh-modal">
                                <div className="flex aic jcc" style={{ padding: "10px 0px" }}>
                                    <div className="flex">
                                        { this.state.role === "employee" ?
                                            <img src={staroflife} alt='staroflife' class="hw-45 mr-10" />
                                            :
                                            <div class="profile-image">
                                                <img src={profile} alt="profile_picture" />
                                            </div>
                                        }
                                        <div>
                                            <p style={{ fontFamily: "Arial Rounded MT", fontWeight: "bold", fontSize: "16px", marginBottom: "5px", color: "var(--color-primary)" }}>{this.state.account_data.firstname} {this.state.account_data.lastname}</p>
                                            <p style={{ fontFamily: "Arial Rounded MT", fontSize: "16px" }}>{ this.state.account_data.gender == "MALE" ? "Homme" : "Femme" } - {this.calculateAge(this.state.account_data.birthdate)} ans</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex" style={{ gap: "10px" }}> 
                                    <InputIconComponent placeholder="Prénom" type="firstname" options="mt-10 w-100p" value={this.state.account_data.firstname} onHandleChange={this.updateFirstName}/>
                                    <InputIconComponent placeholder="Nom de famille" type="lastname" options="mt-10 w-100p" value={this.state.account_data.lastname} onHandleChange={this.updateLastName}/>
                                </div>
                                <InputIconComponent placeholder="E-mail" type="email" value={this.state.account_data.email} onHandleChange={this.updateEmail} icon={<svg xmlns="http://www.w3.org/2000/svg" className="hw-25" viewBox="0 0 34.875 34.875"><path d="M17.438.563A17.438,17.438,0,1,0,34.875,18,17.434,17.434,0,0,0,17.438.563Zm0,6.75A6.188,6.188,0,1,1,11.25,13.5,6.188,6.188,0,0,1,17.438,7.313Zm0,24.188a13.474,13.474,0,0,1-10.3-4.8,7.839,7.839,0,0,1,6.926-4.2,1.72,1.72,0,0,1,.5.077,9.309,9.309,0,0,0,2.876.485,9.274,9.274,0,0,0,2.876-.485,1.72,1.72,0,0,1,.5-.077,7.839,7.839,0,0,1,6.926,4.2A13.474,13.474,0,0,1,17.438,31.5Z" transform="translate(0 -0.563)"/></svg>} options="mt-10" />
                                <InputIconComponent placeholder="Date de naissance" type="date" value={dayjs(this.state.account_data.birthdate).format("YYYY-MM-DD")} onHandleChange={this.updateBirthdate} icon={<svg className="hw-25" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"/> <line x1="16" y1="2" x2="16" y2="6"/> <line x1="8" y1="2" x2="8" y2="6"/> <line x1="3" y1="10" x2="21" y2="10"/></svg>} options="mt-10" />
                                <fieldset className='mt-10' style={{ border: "3px solid var(--color-primary)", fontFamily: "Arial" }}>
                                    <legend style={{ marginLeft: "5px", padding: "0px 7px", fontFamily: "Arial Rounded MT", fontWeight: "bold", fontSize: "16px", color: "var(--color-primary)" }}>Roles</legend>
                                    <div className="flex jcsb" style={{ padding: "10px", gap: "10px" }}>
                                        { this.state.account_data.roles ?
                                            ([
                                                { name: "USER", label: "Utilisateur" }, 
                                                { name: "EMPLOYEE", label: "Employer" }, 
                                                { name: "BOSS", label: "Patron" }
                                            ]).map((role, index) => {
                                                return (
                                                    <div class="tile" key={index}>
                                                        <input type="checkbox" name={role.name} id={role.name} value={role.name} checked={(this.state.account_data.roles).includes(role.name)} onChange={role.name == "USER" ? null : this.updateRoles} />
                                                        <label className='label_custom' for={role.name}>
                                                            { role.name === "EMPLOYEE" || role.name === "BOSS" ?
                                                                <img src={staroflife} alt='staroflife' class="hw-45" />
                                                                :
                                                                <div class="profile-image" style={{ margin: 0 }}>
                                                                    <img src={profile} alt="profile_picture" />
                                                                </div>
                                                            }
                                                            <h6>{role.label}</h6>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </div>
                                </fieldset>
                                <div className="mt-10" style={{ fontFamily: "Segoe UI SemiBold", textAlign: "center", fontSize: "15px" }}>Créé le {dayjs(this.state.account_data.created_at).format("DD/MM/YYYY à HH:mm:ss")}</div>
                                <button className="search_button" style={{ fontSize: "17px" }} onClick={this.state.updateAccount ? null : this.onClickUpdateInformations}>Modifier les informations</button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <FooterComponent />
            </>
        );
    }
}

export default AdministrationUsers;
