import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Welcome from "./AccessAmbulance/Home";
import Faq from "./AccessAmbulance/Faq";
import SignIn from "./AccessAmbulance/SignIn";
import SignUp from "./AccessAmbulance/SignUp";
import Reservations from "./AccessAmbulance/Reservations";
import Administration from "./AccessAmbulance/Administration";
import AdministrationUsers from "./AccessAmbulance/AdministrationUsers";
import AdministrationRdv from "./AccessAmbulance/AdministrationRdv";
import EmailVerify from "./AccessAmbulance/EmailVerify";
import ChangePassword from "./AccessAmbulance/ChangePassword";
import ForgetPassword from "./AccessAmbulance/ForgetPassword";
import LegalMention from "./AccessAmbulance/LegalMentions";
import LegalTerm from "./AccessAmbulance/LegalTerms";

class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Welcome} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/account/verify/:code" component={EmailVerify} />
                    <Route exact path="/account/password/:code" component={ChangePassword} />
                    <Route exact path="/account/forget" component={ForgetPassword} />
                    <Route exact path="/reservations" component={Reservations} />
                    <Route exact path="/administration" component={Administration} />
                    <Route exact path="/administration/reservation" component={AdministrationRdv} />
                    <Route exact path="/administration/users" component={AdministrationUsers} />
                    <Route exact path="/legal/mentions" component={LegalMention} />
                    <Route exact path="/legal/terms" component={LegalTerm} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router
