import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';

import star_life_left from '../asserts/img/star_life-left.webp';
import star_life_right from '../asserts/img/star_life-right.webp';

class LegalMention extends Component {
    render() {
        return (
            <>
                <HeaderComponent />
                <img className="starlife_backstage starlife_left" alt='starlife_backstage-left' src={star_life_left} style={{ marginTop: "80px" }} />
                <img className="starlife_backstage starlife_right"  alt='starlife_backstage-right' src={star_life_right} />

                <div className="legal_mention_container">
                    <h1 className="legal_mention_title">Mentions Légales</h1>
                    
                    <div className="legal_mention_text">
                        Conformément aux dispositions de l’article 6 III-1 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique (LCEN), cette page a pour objet de définir les mentions légales et
                        les conditions générales d’utilisation du site web accessible à l'adresse { document.location.hostname }.
                        <br/><br/>
                        <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Éditeur du site :</div>
                        <div className="flex">
                            <span>Nom : Ilyes El Majouti</span>
                            <a className="hw-20 ml-4" href="https://github.com/Ilyes-El-Majouti" aria-label="Github" style={{ textDecoration: "none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="hw-20" viewBox="0 0 496 512">
                                    <path opacity="1" fill="#000" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/>
                                </svg>
                            </a>
                            <a className="hw-20 ml-4" href="https://www.linkedin.com/in/ilyes-el-majouti/" aria-label="Linkdin" style={{ textDecoration: "none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="hw-20" viewBox="0 0 448 512">
                                    <path opacity="1" fill="#000" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                                </svg>
                            </a>
                        </div>
                        Adresse e-mail : <a href="mailto:ilyes.elmajouti@gmail.com" style={{ textDecoration: "none", color: "var(--color-primary)" }}>ilyes.elmajouti@gmail.com</a>
                        <br/><br/>
                        <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Hébergeur du site internet :</div>
                        OVH
                        <br/>
                        2 Rue Kellermann
                        <br/>
                        59100 Roubaix
                        <br/><br/> 
                        Adresse : RCS Lille Métropole 424 761 419 00045
                        <br/> 
                        Code APE 2620Z
                        <br/>
                        N° TVA : FR 22 424 761 419
                        <br/>
                        Siège social : 2 rue Kellermann - 59100 Roubaix - France
                        <br/><br/>
                        <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Responsable de la publication :</div>
                        <span style={{ color: "var(--color-primary)", fontFamily: "Arial Bold" }}>{ process.env.REACT_APP_APP_NAME }</span>
                        <br/>
                        <b>M Abid BOUADUAHAR</b>
                        <br/>
                        182 Rue roger salengro
                        <br/>
                        59260 LILLE
                        <br/><br/>
                        SARL au capital de 7 500 euros
                        <br/>
                        RCS 804 485 597 R.C.S. Lille
                        <br/>
                        SIREN 804485597
                        <br/>
                        TVA FR94804485597
                        <br/>
                        APE 8690A
                        <br/><br/>
                        L'utilisateur peut également, pour des motifs légitimes, s'opposer au traitement des données le concernant. L'utilisateur trouvera des informations sur ses droits et devoirs et sur la protection des données individuelles sur le site de la "Commission Nationale Informatique et Libertés".
                        Les utilisateurs du site Internet {document.location.hostname} sont invités à faire connaître à <b>M Abid BOUADUAHAR</b> leurs remarques sur d'éventuels dysfonctionnements du site, au regard des libertés individuelles, à l'une ou l'autre des adresses indiquées ci-avant.
                        <br/><br/>
                        <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Propriété intellectuelle :</div>
                        Le présent site Internet pris dans sa globalité et chacun des éléments qui le composent pris indépendamment, notamment les programmes et développements spécifiques et les contenus incluant des données, textes, images fixes ou animées, logotypes, sons, graphiques, fichiers, sont la propriété exclusive du responsable de la publication, du créateur du site Internet ou de tiers qui lui ont concédé une licence. Toute représentation totale ou partielle du site ou de l'un des éléments qui le composent sans l'autorisation expresse du titulaire des droits de propriété intellectuelle est interdite et constituerait une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.
                        Les bases de données présentes sur le site sont protégées par les articles L.341-1 et suivants du Code de la propriété intellectuelle et est sanctionnée toute extraction ou réutilisation qualitativement ou quantitativement substantielle du contenu des bases de données.
                        <br/><br/>
                        <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Données personnelles :</div>
                        Pour plus d'informations sur la collecte et le traitement des données personnelles, veuillez consulter notre <NavLink to="/legal/terms" style={{ textDecoration: "none", color: "var(--color-primary)" }}>politique de confidentialité</NavLink>.
                        <br/><br/>
                        <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Conditions générales d'utilisation :</div>
                        Les utilisateurs du site {document.location.hostname} reconnaissent avoir pris connaissance des présentes mentions légales et s'engagent à les respecter.
                    </div>

                    <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Loi informatique et libertés :</div>
                    <div className="legal_mention_text">Conformément à l'article 39 de la loi "Informatique et Libertés" du 6 janvier 1978, l'utilisateur dispose d'un droit d'accès, de modification, de rectification et de suppression des données le concernant. Pour l'exercice de ces droits, l'utilisateur est invité à s'adresser par courriel à <b>contact@{document.location.hostname}</b>. Il peut aussi écrire au Responsable de publication.<br/><br/>L'utilisateur peut également, pour des motifs légitimes, s'opposer au traitement des données le concernant. L'utilisateur trouvera des informations sur ses droits et devoirs et sur la protection des données individuelles sur le site de la "Commission Nationale Informatique et Libertés". Les utilisateurs du site Internet <b>{document.location.hostname}</b> sont invités à contacter par courriel à <b>contact@{document.location.hostname}</b> leurs remarques sur  d'éventuels dysfonctionnements du site, au regard des libertés individuelles, à l'une ou l'autre des adresses indiquées ci-avant.<br/><br/>Les bases de données présentes sur le site sont protégées par les articles L.341-1 et suivants du Code de la propriété intellectuelle et est sanctionnée toute extraction ou réutilisation qualitativement ou quantitativement substantielle du contenu des bases de données. Politique de protection des données<br/><br/>La présente politique de protection des données permet de vous informer sur les conditions dans lesquelles vos données personnelles sont susceptibles d’être traitées et sur vos droits en application de la réglementation Informatique et libertés. La société <b>{ process.env.REACT_APP_APP_NAME }</b>, en qualité de responsable de traitement, s’engage à garantir la confidentialité et la sécurité de vos données à caractère personnel.</div>
                    
                    <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Comment sont traitées vos données ?</div>
                    <div className="legal_mention_text">Nous recueillons et utilisons les informations personnelles transmises à votre initiative depuis la prise de rendez-vous de véhicule disponibles sur le présent site Internet. Au cas particulier, les données sollicitées pour une prise de rendez-vous s’avèrent indispensables pour permettre votre authentification et répondre à vos besoins. Le traitement de vos données a pour finalité la gestion administrative des dossiers-clients et de la relation-client. Ainsi, nous nous engageons à ne traiter vos données personnelles que lorsque leur traitement est nécessaire à la formation et/ou l’exécution du contrat auquel vous êtes parti ou entendez être parti. Vos données ne sont conservées que pour la durée strictement nécessaire à la finalité susvisée et pour répondre à certaines obligations légales, notamment en matière de lutte contre la fraude. Elles ne sont en aucun cas partagées ou vendues à un tiers pour un usage commercial.</div>

                    <div style={{ fontFamily: "Lemon Milk Pro Bold", fontSize: "15px", marginBottom: "10px" }}>Quels sont vos droits ?</div>
                    <div className="legal_mention_text">En application des articles 14 à 22 du Règlement général sur la protection des données n°2016/679 du 27 avril 2016, vous avez la faculté d’exercer les droits suivants sur vos données à caractère personnel : <br/><br/>▪ droit d’accès.<br/>▪ droit de rectification.<br/>▪ droit d’opposition et d’effacement.<br/>▪ droit à la limitation du traitement.<br/>▪ droit à la portabilité de vos données.<br/><br/>Vous pouvez également formuler des directives relatives à la conservation, à l'effacement et à la communicationde vos données à caractère personnel après votre décès, conformément à l’article 40-1 de la loi 78-17 du 6 janvier 1978. Ces directives peuvent être générales ou particulières. Si vous deviez constater le non-respect de la réglementation sur la protection des données, vous avez le droit de déposer une plainte auprès de la CNIL. Nous vous recommandons cependant de bien vouloir en premier lieu résoudre tout problème avec nous avant de déposer votre plainte. Pour toute demande ou préoccupation concernant la politique de protection des données, veuillez nous contacter par courriel électronique : <b>contact@{document.location.hostname}</b>. </div>
                </div>
                <FooterComponent />
            </>
        );
    }
}

export default LegalMention;
