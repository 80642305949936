import React, { Component } from 'react';
import { getToken } from './utilities/AccountManager';

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import RadioComponent from './components/RadioComponent';
import Pagination from './components/Pagination';
import InputIconComponent from './components/InputIconComponent';
import InputPhoneIconComponent from './components/InputPhoneIconComponent';

import axios from "axios";
import dayjs from 'dayjs';
import Modal from 'react-modal';
import { fr } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';

import "react-day-picker/dist/style.css";

import star_life_left from '../asserts/img/star_life-left.webp';
import star_life_right from '../asserts/img/star_life-right.webp';

const ModalHomeStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "transparent",
        border: "none",
        width: "100%",
        margin: "auto",
        overflow: "unset"
    },
    overlay: {
        backgroundColor: "rgba(172, 172, 172, 0.41)",
        zIndex: '5'
    }
};

class Administration extends Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDay: new Date(),
            reservations: [],
            totalReservations: 0,
            offset: 0,
            limit: 10,
            information_reservation: false,
            reservation_data: {},
            reservation_update: {},
            types: [
                {
                    id: "sitting",
                    label: "Demi assis ou allongés"
                },
                {
                    id: "vsl",
                    label: "VSL"
                }
            ],
            intervalTimes: [
                "07:00", "07:10", "07:20", "07:30", "07:40", "07:50",
                "08:00", "08:10", "08:20", "08:30", "08:40", "08:50",
                "09:00", "09:10", "09:20", "09:30", "09:40", "09:50",
                "10:00", "10:10", "10:20", "10:30", "10:40", "10:50",
                "11:00", "11:10", "11:20", "11:30", "11:40", "11:50",
                "12:00", "12:10", "12:20", "12:30", "12:40", "12:50",
                "13:00", "13:10", "13:20", "13:30", "13:40", "13:50",
                "14:00", "14:10", "14:20", "14:30", "14:40", "14:50",
                "15:00", "15:10", "15:20", "15:30", "15:40", "15:50",
                "16:00", "16:10", "16:20", "16:30", "16:40", "16:50",
                "17:00", "17:10", "17:20", "17:30", "17:40", "17:50",
                "18:00"
            ],
            type: "sitting",
            cancel_reservation: false,
            modify_reservation: false,
            error_number: false
        };
    }

    async componentDidMount() {
        try {
            if (localStorage.getItem("login") == true && localStorage.getItem("token") != null) { 
                const token = await getToken();

                try {
                    if (token) {
                        const userinfosToken = token.split(".")[1];
                        const userinfos = JSON.parse(atob(userinfosToken));

                        if ((userinfos.roles).includes("EMPLOYEE") || (userinfos.roles).includes("BOSS")) {
                            this.requestOnReservations();
                            this.setState({ userinfos: userinfos });
                        } else {
                            this.props.history.push("/");
                        }
                    }
                } catch (error) { 
                    this.props.history.push("/");
                }
            } else {
                this.props.history.push("/");
            }
        } catch (error) {
            this.props.history.push("/");
        }
    }

    requestOnReservations = async (limit = null) => {
        const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/api/v1/administration/get/reservations/${this.state.offset || 0}`,
            params: {
                date: dayjs(this.state.selectedDay).format("DD-MM-YYYY"),
                type: this.state.type,
                limit: (limit == null ? this.state.limit : limit) || 20
            },
            headers: { 'Authorization': `Bearer ${await getToken()}` }
        });
        
        const { reservations, totalCount } = response.data;

        this.setState({
            reservations: reservations,
            totalReservations: totalCount
        });
    }

    onChangeLimit = async (e) => {
        this.setState({ currentPage: 1, offset: 0, limit: e.target.value }, async () => {
            await this.requestOnReservations(e.target.value);
        });
    }

    onChangeType = async (e) => {
        this.setState({ currentPage: 1, offset: 0, type: e.target.value }, async () => {
            await this.requestOnReservations();
        });
    }

    onInformationsReservation = async (id) => {
        const reservation = this.state.reservations.find(res => res._id === id);
      
        if (!reservation) {
            console.error(`No reservation found with id ${id}`);
            return;
        }

        let userinfos, reservation_data;

        if(reservation.lastname == null || reservation.number_phone == null) {
            userinfos = await this.requestOnUserinfos(reservation.user_id);
            reservation_data = { userinfos, reservation };
        } else {
            reservation_data = { 
                userinfos: {
                    gender: reservation.gender,
                    lastname: reservation.lastname
                },
                reservation
            };

            if(reservation.firstname) {
                Object.assign(reservation_data.userinfos, { firstname: reservation.firstname });
            }
            
            if(reservation.birthdate) {
                Object.assign(reservation_data.userinfos, { birthdate: reservation.birthdate });
            }
        }
      
        await this.setState({ reservation_data }, await this.openCancelReservation);
    }

    requestOnUserinfos = async (userid) => {
        const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/api/v1/administration/get/userinformations/${userid}`,
            headers: { 'Authorization': `Bearer ${await getToken()}` }
        });
        
        const { success, userinfos } = response.data;

        if(success == 1) {
            return userinfos;
        }
    }

    openCancelReservation = () => {
        this.setState({ information_reservation: !this.state.information_reservation });
    }

    refresh = async () => {
        await this.requestOnReservations();
    }

    handleDayClick(day, { selected }) {
        this.setState({
            selectedDay: selected ? undefined : day,
        }, async () => {
            await this.requestOnReservations();
        });
    }

    calculateAge(birthdate) {
        const dateNaissanceObj = dayjs(birthdate);
        const dateActuelle = dayjs();

        const differenceAnnees = dateActuelle.diff(dateNaissanceObj, 'year');
        return differenceAnnees;
    }

    confirmeCancelReservation = async () => {        
        const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/api/v1/administration/reservations/delete/`,
            data: {
                id_reservation: this.state.reservation_data.reservation._id
            },
            headers: { 'Authorization': `Bearer ${await getToken()}` }
        });

        if(response.data.success) {
            const delete_reservation = (this.state.reservations).filter((reservation) => reservation._id !== this.state.reservation_data.reservation._id);
            
            this.setState({ reservations: delete_reservation, totalReservations: this.state.totalReservations - 1 });
            this.openDeleteReservation()
            this.openCancelReservation();
        }
    }

    modifyReservation = () => {
        if((this.state.userinfos.roles).includes("BOSS")) {
            this.setState({ modify_reservation: !this.state.modify_reservation });
        }
    }

    openDeleteReservation = () => {
        this.setState({ cancel_reservation: !this.state.cancel_reservation });
    }

    confirmeModifyReservation = async () => {
        if(this.state.reservation_update.length == 0) {
            this.modifyReservation();
        } else {
            const response = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API}/api/v1/administration/update/reservation/${this.state.reservation_data.reservation._id}/`,
                data: this.state.reservation_update,
                headers: { 'Authorization': `Bearer ${await getToken()}` }
            });

            if(response.data.success) {
                this.setState({ reservation_update: {} });
                this.modifyReservation()
            }
        }
    }

    render() {
        const reservations = (this.state.reservations).map((reservation, index) => {
            return (
                <div className="flex jcsb" style={{ borderBottom: "1px solid var(--color-gray)", padding: "5px 0px" }} key={index}>
                    <div className="flex aic" style={{ padding: "10px 0px" }}>
                        <div className="pr" style={{ fontSize: "15px" }}>
                            <div className="flex pr">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20" style={{ minWidth: "20px" }}>
                                    <path d="M9.935,18.87A8.935,8.935,0,1,0,1,9.935,8.935,8.935,0,0,0,9.935,18.87Zm0-6.5A2.437,2.437,0,1,0,7.5,9.935,2.437,2.437,0,0,0,9.935,12.372Z" transform="translate(-1 -1)" fillRule="evenodd"></path>
                                </svg>
                                <span style={{ fontFamily: "Arial Bold", margin: "auto", marginLeft: "5px" }}>{reservation.startpoint}</span>
                            </div>
                            <div className="line" style={ (reservation.startpoint).length > 43 ? { left: "7px", height: "50%" } : (reservation.endpoint).length > 43 ? { left: "7px", height: "25%", top: "-10px" } :{ left: "7px", height: "25%" }}></div>
                            <div className="flex pr mt-10">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20" style={{ minWidth: "20px" }}>
                                    <path d="M19,2H2V19H19ZM13.05,7.95H7.95v5.1h5.1Z" transform="translate(-2 -2)" fillRule="evenodd"></path>
                                </svg>
                                <span style={{ marginLeft: "5px", fontFamily: "Arial Bold", marginTop: "auto" }}>{reservation.endpoint}</span>
                            </div>
                        </div>
                    </div>
                    <div className="grid aic" style={{ minWidth: "fit-content", width: "fit-content" }}>
                        <div style={ (dayjs(`${dayjs(reservation.date).format("DD-MM-YYYY")} ${reservation.time}`, "DD-MM-YYYY HH:mm")).isAfter(dayjs()) ? { background: "#00ab4c", padding: "4px 7px", borderRadius: "7px", color: "white", fontFamily: "Segoe UI SemiBold", fontSize: "13px", minWidth: "fit-content", width: "fit-content" } : { background: "#e9e9ed", padding: "4px 7px", borderRadius: "7px", color: "var(--color-text-primary)", fontFamily: "Segoe UI SemiBold", fontSize: "13px", minWidth: "fit-content", width: "fit-content" }}>{`${dayjs(reservation.date).format("DD-MM-YYYY")} à ${reservation.time}`}</div>
                        <button onClick={() => this.onInformationsReservation(reservation._id)} style={{ color: "red", padding: "5px", fontFamily: "Segoe UI SemiBold", cursor: "pointer" }}>Gerer la course</button>
                    </div>
                </div>
            )
        })

        return (
            <>
                <HeaderComponent />
                <img className="starlife_backstage starlife_left" alt='starlife_backstage-left' src={star_life_left} style={{ marginTop: "80px" }} />
                <img className="starlife_backstage starlife_right" alt='starlife_backstage-right' src={star_life_right} />

                <div className="container_user">
                    <div className="container_about">
                        <h2 className="about_meet mb-20 text-center">Calendrier de rendez-vous</h2>
                        <div style={{ display: "grid", justifyContent: "center" }}>
                            <div style={{ width: "fit-content", margin: "auto" }}>
                                <RadioComponent types={this.state.types} selected={this.state.type} nameRadio="radio_type" options="br-black" onHandleTypes={this.onChangeType}/>
                            </div>
                            <DayPicker
                                showOutsideDays
                                locale={fr}
                                mode="single"
                                selected={this.state.selectedDay}
                                onSelect={this.handleDayClick}
                            />
                        </div>
                    </div>
                    <div className="container_rdv">
                        <div className="flex jcsb" style={{ fontFamily: "Roboto Bold", marginBottom: "15px" }}>
                            <div className="flex aic">
                                <span>Resultats ({this.state.totalReservations || 0})</span>
                                <svg className="hw-16" style={{ cursor: "pointer", marginLeft: "5px" }} onClick={this.refresh} viewBox="0 0 118.04 122.88">
                                    <path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z"/>
                                </svg>
                            </div>
                            <select style={{ fontSize: "14px", padding: "0px", width: "unset" }} value={this.state.limit} onChange={this.onChangeLimit}>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                        { (this.state.reservations).length != 0 ?
                            <>
                                <div style={{ /*maxHeight: "405px", height: "405px",*/ overflow: "auto" }}>
                                    {reservations.reverse()}
                                </div>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={this.state.currentPage}
                                    totalCount={this.state.totalReservations}
                                    pageSize={this.state.limit}
                                    onPageChange={(page) => this.setState({ currentPage: page, offset: (page - 1) * this.state.limit }, () => this.requestOnReservations())}
                                />
                            </>
                            :
                            <p className="text-center">Aucune reservations</p>
                        }
                    </div>
                    <Modal isOpen={this.state.information_reservation} onRequestClose={this.openCancelReservation} style={ModalHomeStyles}>
                        <div className="modal-card nj abs-c">
                            <div className='pr'>
                                <h2 className="text-center mb-10" style={{ color: "var(--color-primary)", fontFamily: "Lemon Milk Pro Medium" }}>
                                    Fiche Patient
                                </h2>
                                <button onClick={this.openCancelReservation} style={{ position: "absolute", left: "97%", transform: "translate(-50%, -50%)", top: "18px", padding: "7px", borderRadius: "13px", background: "#0000BB14", height: "40px", width: "40px", justifyContent: "center", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <svg version="1.1" viewBox="0 0 512 512" style={{ height: "17px",width: "17px",fill: "var(--color-primary)" }}>
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="maxh-modal">
                                { this.state.reservation_data.reservation ? 
                                    <>
                                        { this.state.reservation_data.userinfos ?
                                            <div className="flex jcc mb-20">
                                                <div style={{ fontFamily: "Segoe UI SemiBold", width: "max-content" }}>
                                                    <p>Nom de famille: <b>{(this.state.reservation_data.userinfos.lastname).toUpperCase()}</b></p>
                                                    { this.state.reservation_data.userinfos.firstname ?
                                                        <p>Prénom: <b>{(this.state.reservation_data.userinfos.firstname).toUpperCase()}</b></p>
                                                        :
                                                        null
                                                    }
                                                    { this.state.reservation_data.userinfos.birthdate ?
                                                        <>
                                                            <p>Âge: <b>{this.calculateAge(this.state.reservation_data.userinfos.birthdate)} ans</b></p>
                                                            <p>Date de naissance: <b>{dayjs(this.state.reservation_data.userinfos.birthdate).format("DD/MM/YYYY")}</b></p>
                                                        </>
                                                        :null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <h2 className="text-center mb-10" style={{ color: "var(--color-primary)", fontFamily: "Lemon Milk Pro Medium" }}>
                                            Itinéraire de la course
                                        </h2>

                                        <div className="mb-20" style={{ fontFamily: "Segoe UI SemiBold", textAlign: "center", fontSize: "15px" }}>Le {`${dayjs(this.state.reservation_data.reservation.date).format("DD-MM-YYYY")} à ${this.state.reservation_data.reservation.time}`}</div>
                                        <div className="mb-20" style={{ fontFamily: "Segoe UI SemiBold", textAlign: "center", fontSize: "15px" }}>Numéro de télephone: <b>{`0${this.state.reservation_data.reservation.number_phone}`}</b></div>

                                        <div className="jcc mb-10" style={{ display: "grid" }}>
                                            <div className="pr" style={{ fontSize: "15px" }}>
                                                <div className="flex pr">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20">
                                                        <path d="M9.935,18.87A8.935,8.935,0,1,0,1,9.935,8.935,8.935,0,0,0,9.935,18.87Zm0-6.5A2.437,2.437,0,1,0,7.5,9.935,2.437,2.437,0,0,0,9.935,12.372Z" transform="translate(-1 -1)" fillRule="evenodd"></path>
                                                    </svg>
                                                    <span style={{ fontFamily: "Arial Bold", margin: "auto", marginLeft: "5px" }}>{this.state.reservation_data.reservation.startpoint}</span>
                                                </div>
                                                <div className="line" style={{ left: "7px", height: "25%" }}></div>
                                                <div className="flex pr mt-10">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20">
                                                        <path d="M19,2H2V19H19ZM13.05,7.95H7.95v5.1h5.1Z" transform="translate(-2 -2)" fillRule="evenodd"></path>
                                                    </svg>
                                                    <span style={{ marginLeft: "5px", fontFamily: "Arial Bold", marginTop: "auto" }}>{this.state.reservation_data.reservation.endpoint}</span>
                                                </div>
                                            </div>
                                        </div>

                                        { (this.state.userinfos.roles).includes("BOSS") ?
                                            <button className="search_button" style={{ padding: "14px 0px", background: "black" }} onClick={this.modifyReservation}>Modification de la course</button>
                                            :
                                            null
                                        }
                                        <div className="flex jcc">
                                            <a className="search_button" style={{ marginRight: "20px", padding: "14px 0px", background: "var(--color-primary)", textDecoration: "none", textAlign: "center", fontSize: "13px" }} href={`tel:+33${this.state.reservation_data.reservation.number_phone}`}>Appeler le patient</a>
                                            <button className="search_button" style={{ padding: "14px 0px", background: "#dd1414" }} onClick={this.openDeleteReservation}>Annulation de la course</button>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </Modal>

                    <Modal isOpen={this.state.modify_reservation} onRequestClose={this.modifyReservation} style={ModalHomeStyles}>
                        <div className="modal-card nj abs-c">
                            <div className='pr'>
                                <h2 className="text-center mb-10" style={{ color: "var(--color-primary)", fontFamily: "Lemon Milk Pro Medium" }}>
                                    Modification Patient
                                </h2>
                                <button onClick={this.modifyReservation} style={{ position: "absolute", left: "97%", transform: "translate(-50%, -50%)", top: "18px", padding: "7px", borderRadius: "13px", background: "#0000BB14", height: "40px", width: "40px", justifyContent: "center", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <svg version="1.1" viewBox="0 0 512 512" style={{ height: "17px",width: "17px",fill: "var(--color-primary)" }}>
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="maxh-modal">
                                { this.state.reservation_data.reservation ? 
                                    <>
                                        <div className="jcc mb-10" style={{ display: "grid" }}>
                                            <div className="pr" style={{ fontSize: "15px" }}>
                                                <div className="flex pr">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20">
                                                        <path d="M9.935,18.87A8.935,8.935,0,1,0,1,9.935,8.935,8.935,0,0,0,9.935,18.87Zm0-6.5A2.437,2.437,0,1,0,7.5,9.935,2.437,2.437,0,0,0,9.935,12.372Z" transform="translate(-1 -1)" fillRule="evenodd"></path>
                                                    </svg>
                                                    <span style={{ fontFamily: "Arial Bold", margin: "auto", marginLeft: "5px" }}>{this.state.reservation_data.reservation.startpoint}</span>
                                                </div>
                                                <div className="line" style={{ left: "7px", height: "25%" }}></div>
                                                <div className="flex pr mt-10">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20">
                                                        <path d="M19,2H2V19H19ZM13.05,7.95H7.95v5.1h5.1Z" transform="translate(-2 -2)" fillRule="evenodd"></path>
                                                    </svg>
                                                    <span style={{ marginLeft: "5px", fontFamily: "Arial Bold", marginTop: "auto" }}>{this.state.reservation_data.reservation.endpoint}</span>
                                                </div>
                                            </div>
                                        </div>

                                        { this.state.reservation_data.userinfos ?
                                            <div className="flex jcc mb-20">
                                                <div style={{ fontFamily: "Segoe UI SemiBold", width: "100%" }}>
                                                    { this.state.reservation_data.userinfos.birthdate ?
                                                        <>
                                                            <p>Âge: <b>{this.calculateAge(this.state.reservation_data.userinfos.birthdate)} ans</b></p>
                                                            <p>Date de naissance: <b>{dayjs(this.state.reservation_data.userinfos.birthdate).format("DD/MM/YYYY")}</b></p>
                                                        </>
                                                        :null
                                                    }

                                                    { this.state.reservation_data.userinfos.firstname ?
                                                        <InputIconComponent placeholder="Prénom" type="firstname" options="mt-10 w-100p" value={this.state.reservation_data.userinfos.firstname} onHandleChange={(e) => 
                                                            this.setState((prevState) => 
                                                                ({ 
                                                                    reservation_data: { 
                                                                        ...prevState.reservation_data,
                                                                        reservation: {
                                                                            ...prevState.reservation_data.reservation, 
                                                                            firstname: e.target.value
                                                                        },
                                                                        userinfos: { 
                                                                            ...prevState.reservation_data.userinfos, 
                                                                            firstname: e.target.value 
                                                                        }
                                                                    }, 
                                                                    reservation_update: { 
                                                                        ...prevState.reservation_update, 
                                                                        firstname: e.target.value 
                                                                    } 
                                                                })
                                                            )}
                                                        />
                                                        :
                                                        null
                                                    }

                                                    <InputIconComponent placeholder="Nom de famille" type="lastname" options="mt-10 w-100p" value={this.state.reservation_data.userinfos.lastname} onHandleChange={(e) => 
                                                        this.setState((prevState) => 
                                                            ({ 
                                                                reservation_data: { 
                                                                    ...prevState.reservation_data,
                                                                    reservation: {
                                                                        ...prevState.reservation_data.reservation, 
                                                                        lastname: e.target.value
                                                                    },
                                                                    userinfos: { 
                                                                        ...prevState.reservation_data.userinfos, 
                                                                        lastname: e.target.value 
                                                                    }
                                                                }, 
                                                                reservation_update: { 
                                                                    ...prevState.reservation_update, 
                                                                    lastname: e.target.value 
                                                                } 
                                                            })
                                                        )}
                                                    />
                                                    <div className="flex" style={{ gap: "10px" }}>
                                                        <InputIconComponent placeholder="Date de réservation" type="date" options="mt-10 w-100p" value={dayjs(this.state.reservation_data.reservation.date).format("YYYY-MM-DD")} onHandleChange={(e) => 
                                                            this.setState((prevState) => 
                                                                ({ 
                                                                    reservation_data: { 
                                                                        ...prevState.reservation_data, 
                                                                        reservation: { 
                                                                            ...prevState.reservation_data.reservation,
                                                                            date: e.target.value
                                                                        }
                                                                    } ,
                                                                    reservation_update: { 
                                                                        ...prevState.reservation_update, 
                                                                        date: e.target.value 
                                                                    } 
                                                                })
                                                            )}
                                                        />
                                                        <div className={`form-input pr mt-10 w-100p`}>
                                                            <svg className="hw-25" viewBox="0 0 24 24">
                                                                <path d="M12 2.83398C6.91671 2.83398 2.83337 6.91732 2.83337 12.0007C2.83337 17.084 6.91671 21.1673 12 21.1673C17.0834 21.1673 21.1667 17.084 21.1667 12.0007C21.1667 6.91732 17.0834 2.83398 12 2.83398ZM17 13.6673H10.3334V5.33398H12.8334V11.1673H17V13.6673Z" fill="#000000"></path>
                                                            </svg>
                                                            <select value={this.state.reservation_data.reservation.time} onChange={(e) => 
                                                                this.setState((prevState) => 
                                                                    ({ 
                                                                        reservation_data: { 
                                                                            ...prevState.reservation_data, 
                                                                            reservation: { 
                                                                                ...prevState.reservation_data.reservation,
                                                                                time: e.target.value
                                                                            }
                                                                        },
                                                                        reservation_update: { 
                                                                            ...prevState.reservation_update, 
                                                                            time: e.target.value 
                                                                        } 
                                                                    })
                                                                )}
                                                            >
                                                                { 
                                                                    (this.state.intervalTimes).map((time, index) => {
                                                                        return <option value={time} key={index}>{time}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <InputPhoneIconComponent placeholder="X XX XX XX XX" value={this.state.reservation_data.reservation.number_phone} onHandleChange={(e) => (e.target.value).replace(/[^0-9+]/g, '') == "" && this.state.error_number == true ? 
                                                        this.setState((prevState) =>
                                                            ({ 
                                                                reservation_data: 
                                                                { 
                                                                    ...prevState.reservation_data, 
                                                                    reservation: { 
                                                                        ...prevState.reservation_data.reservation, 
                                                                        number_phone: e.target.value.replace(/[^0-9+]/g, '')
                                                                    }
                                                                },
                                                                reservation_update: {
                                                                    ...prevState.reservation_update,
                                                                    number_phone: e.target.value.replace(/[^0-9+]/g, '')
                                                                }
                                                            })
                                                        )
                                                        : 
                                                        this.setState((prevState) => 
                                                            ({ 
                                                                reservation_data: 
                                                                { 
                                                                    ...prevState.reservation_data, 
                                                                    reservation: { 
                                                                        ...prevState.reservation_data.reservation, 
                                                                        number_phone: e.target.value.replace(/[^0-9+]/g, '')
                                                                    }
                                                                },
                                                                error_number: false,
                                                                reservation_update: {
                                                                    ...prevState.reservation_update,
                                                                    number_phone: e.target.value.replace(/[^0-9+]/g, '')
                                                                }
                                                            })
                                                        )} 
                                                        icon={<svg className="hw-25" fill="#000000" viewBox="0 0 612.001 612"><g><path d="M588.074,199.311c-11.954-12.377-25.671-16.74-35.288-18.225c-8.256-62.36-15.939-94.828-275.988-94.828   C16.75,86.258,0,125.46,0,193.498v11.605c0,18.411,14.925,33.336,33.336,33.336h83.427c18.411,0,33.336-14.925,33.336-33.336   v-11.605c0-52.705,80.699-54.319,126.698-54.319c45.998,0,126.697,1.614,126.697,54.319v11.605   c0,18.411,14.926,33.336,33.337,33.336h83.427c18.411,0,33.337-14.925,33.337-33.336v-4.464c6.146,1.51,13.907,4.794,20.776,11.905   c16.747,17.347,22.305,50.861,16.068,96.927c-10.815,79.816-42.181,108.325-75.585,117.813v-13.886   c0-14.097-3.524-28.05-10.668-40.242c-33.336-57.053-80.674-107.677-140.823-152.301v-33.717c0-2.619-2.143-4.762-4.762-4.762   h-49.481c-2.667,0-4.762,2.143-4.762,4.762v33.527h-56.481v-33.527c0-2.619-2.143-4.762-4.762-4.762h-49.529   c-2.62,0-4.762,2.143-4.762,4.762v33.527C128.581,265.384,81.195,316.007,47.81,373.156c-7.144,12.192-10.668,26.146-10.668,40.242   v31.384c0,44.72,36.242,80.961,80.961,80.961h315.793c44.018,0,79.744-35.135,80.855-78.884   c53.54-12.54,83.912-56.224,94.562-134.831C616.467,259.232,609.318,221.31,588.074,199.311z M358.727,414.231l-31.774-11.313   c3.371-6.792,5.314-14.421,5.314-22.522c0-28.003-22.716-50.72-50.767-50.72c-28.003,0-50.767,22.717-50.767,50.72   c0,28.051,22.764,50.768,50.767,50.768c10.371,0,20.003-3.121,28.037-8.453l17.429,28.648   c-13.129,8.43-28.707,13.379-45.465,13.379c-46.576,0-84.294-37.766-84.294-84.341c0-46.529,37.718-84.294,84.294-84.294   c46.576,0,84.342,37.766,84.342,84.294C365.842,392.437,363.275,403.867,358.727,414.231z" /></g></svg>} 
                                                        options={this.state.error_number ? "mt-10 br-red" : "mt-10"} 
                                                    />
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <div className="flex jcc" style={{ gap: "10px" }}>
                                            <button className="search_button" style={{ padding: "14px 0px", marginTop: "0px", background: "var(--color-primary)" }} onClick={this.confirmeModifyReservation}>Enregistrer les modifications</button>
                                            <button className="search_button" style={{ padding: "14px 0px", marginTop: "0px", background: "#dd1414" }} onClick={this.modifyReservation}>Annulation de la course</button>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </Modal>

                    <Modal isOpen={this.state.cancel_reservation} onRequestClose={this.openDeleteReservation} style={ModalHomeStyles}>
                        <div className="modal-card nj abs-c">
                            <div className="pr">
                                <h2 className="mb-20" style={{ color: "var(--color-primary)", fontFamily: "Lemon Milk Pro Medium", paddingRight: "40px" }}>
                                    Voulez vous vraiment annuler cette course ?
                                </h2>
                                <button onClick={this.openDeleteReservation} style={{ position: "absolute", left: "97%", transform: "translate(-50%, -50%)", top: "18px", padding: "7px", borderRadius: "13px", background: "#0000BB14", height: "40px", width: "40px", justifyContent: "center", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <svg version="1.1" viewBox="0 0 512 512" style={{ height: "17px",width: "17px",fill: "var(--color-primary)" }}>
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path>
                                    </svg>
                                </button>
                            </div>
                            <p className="text-center mb-20" style={{ fontFamily: "Bahnschrift", padding: "0px 10px", color: "red" }}>
                                Êtes-vous sûr de vouloir annuler cette course en tant qu'employer ? 
                                Cette action est irréversible, et une fois la course supprimée, 
                                toutes les données associées seront définitivement perdues. 
                                Veuillez prendre un moment pour confirmer votre intention d'annuler cette course.
                                <br /><br />
                                N'oubliez pas que vous êtes notre point de contact principal pour toute assistance.
                                Merci de votre compréhension et de votre coopération dans cette démarche.
                            </p>
                            { 
                                <div className="flex jcc">
                                    <button className="search_button" style={{ marginRight: "20px", padding: "14px 0px", background: "#dd1414" }} onClick={this.confirmeCancelReservation}>Confirmer l'annulation</button>
                                    <button className="search_button" style={{ padding: "14px 0px", background: "var(--color-primary)" }} onClick={this.openDeleteReservation}>Abandonner</button>
                                </div>
                            }
                            
                        </div>
                    </Modal>
                </div>
                <FooterComponent />
            </>
        );
    }
}

export default Administration;
