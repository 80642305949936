import React, { Component } from 'react';

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';

import axios from "axios";

import star_life_left from '../asserts/img/star_life-left.webp';
import star_life_right from '../asserts/img/star_life-right.webp';

class EmailVerify extends Component {
    state = {
        error_message: "",
        message: ""
    };

    validateAccount = async () => {
        this.setState({
            error_message: "",
            message: ""
        });

        if (this.props.match.params.code !== "" || this.props.match.params.code !== null) {
            const credentials = {
                code: this.props.match.params.code
            };

            axios.post(`${process.env.REACT_APP_API}/api/v1/account/email/verify`, credentials).then(async (res) => {
                if(res.data.success == 1){
                    this.setState({
                        message: "Compte activé avec succès"
                    }, () => {
                        setTimeout(() => {
                            this.props.history.push("/signin");
                        }, 3000);
                    });
                } else if(res.data.success == 2) {
                    this.setState({
                        error_message: "Code inconnu ou expiré"
                    });
                } else if(res.data.success == 3) {
                    this.setState({
                        error_message: "Compte non activé"
                    });
                } else if(res.data.success == 4) {
                    this.setState({
                        error_message: "Nouveau code envoyé"
                    });
                } else {
                    this.setState({
                        error_message: "Veuillez réessayer plus tard"
                    });
                }
            })
            .catch(() => {
                this.setState({ 
                    error_message: "Veuillez réessayer plus tard"
                });
            });
        } else {
            this.setState({
                error_message: "Veuillez réessayer plus tard"
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent />
                <img className="starlife_backstage starlife_left" alt='starlife_backstage-left' src={star_life_left} style={{ marginTop: "80px" }} />
                <img className="starlife_backstage starlife_right" alt='starlife_backstage-right' src={star_life_right} />

                <div className="legal_mention_container">
                    <h1 className="legal_mention_title">Activer mon compte</h1>
                    <div className="legal_mention_text">
                        Cher utilisateur,
                        <br/><br/>
                        Nous sommes ravis de vous accueillir sur <span style={{ color: "var(--color-primary)", fontFamily: "Arial Bold" }}>{ process.env.REACT_APP_APP_NAME }</span>, 
                        votre plateforme dédiée aux services d'ambulance. Afin de garantir une expérience sécurisée et personnalisée, 
                        veuillez finaliser le processus d'activation de votre compte en suivant les étapes ci-dessous.
                        <br/><br/>
                        En appuyant sur le bouton ci-dessous, vous initierez le processus de vérification de votre adresse e-mail. 
                        Assurez-vous que l'adresse associée à votre compte est correcte, car cela garantira la réception de toutes les informations importantes liées à vos services d'ambulance.
                    </div>
                    { 
                        this.state.error_message != "" ? 
                            <div style={{ 
                                textAlign: "center", 
                                padding: "12px", 
                                background: "#ffb5b5", 
                                color: "red", 
                                fontFamily: "Arial", 
                                marginBottom: "10px" 
                            }}>
                                {this.state.error_message}
                            </div> 
                        :
                        null
                    }
                    { 
                        this.state.message != "" ? 
                            <div style={{ 
                                textAlign: "center", 
                                padding: "12px", 
                                background: "#b5ffb5", 
                                color: "green", 
                                fontFamily: "Arial", 
                                marginBottom: "10px" 
                            }}>
                                {this.state.message}
                            </div> 
                        :
                        null
                    }
                    <button style={{ display: "flex", background: "var(--color-primary)", color: "white", padding: "10px 19px", cursor: "pointer", margin: "auto" }} onClick={this.validateAccount}>Confirmer l'activation du compte</button>
                    <div className="legal_mention_text" style={{ marginTop: "25px",marginBottom: "0px" }}>
                        Si vous rencontrez des difficultés ou si vous avez des questions, 
                        n'hésitez pas à contacter notre équipe d'assistance dédiée. 
                        Nous sommes là pour vous aider à profiter pleinement de votre expérience avec <span style={{ color: "var(--color-primary)", fontFamily: "Arial Bold" }}>{ process.env.REACT_APP_APP_NAME }</span>.
                        <br/><br/>
                        Nous vous remercions de votre confiance envers notre service et sommes impatients de vous accompagner lors de vos besoins médicaux urgents.
                        <br/><br/>
                        Cordialement,
                        <br/>
                        L'équipe d'{ process.env.REACT_APP_APP_NAME }
                    </div>
                </div>
                <FooterComponent />
            </>
        );
    }
}

export default EmailVerify;