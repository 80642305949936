import React, { Component } from 'react';
import { Phone } from 'react-telephone';

function formaterChaine(chaine) {
    const numeroFormate = chaine.replace(/^0?(\d)(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1 $2 $3 $4 $5');
    return numeroFormate.trim();
}

class InputPhoneIconComponent extends Component {
    componentDidMount() {
        const { value } = this.props;

        document.querySelector("input[type='tel'][maxlength='13']").value = formaterChaine(value);
    }
    
    componentDidUpdate(prevProps) {
        const { value } = this.props;

        if(prevProps.value !== value) {
            if(value === "") {
                document.querySelector("input[type='tel'][maxlength='13']").value = "" 
            }
        }else{
            document.querySelector("input[type='tel'][maxlength='13']").value = formaterChaine(value);
        }
    }

    handleKeyDown = (event) => {
        const { nextInput } = this.props;

        if(typeof nextInput === "function") {
            if (event.key === 'Enter') {
                event.preventDefault();
                
                nextInput();
            }
        }
    }
    
    render() {
        const { placeholder, value, onHandleChange, icon, options } = this.props;

        return (
            <>
                <Phone defaultCountry={"fr"}>
                    <div className={`form-input pr mt-5`} style={{ display: "none" }}>
                        <Phone.Country />
                    </div>
                    <div className={`form-input pr ${options || ""}`}>
                        {icon}
                        <p style={ value == null || value == "" ? { color: "black", fontSize: "17px", fontFamily: "Segoe UI", alignItems: "center", display: "flex" } : { fontSize: "17px", fontFamily: "Segoe UI", alignItems: "center", display: "flex", marginTop: "-1.5px"}}>0</p>
                        <Phone.Number placeholder={placeholder} value={value} maxLength={13} onChange={onHandleChange} onKeyDown={this.handleKeyDown} enterkeyhint="go"/>
                    </div>
                </Phone>
            </>
        );
    }
}

export default InputPhoneIconComponent;