const initialState = {
    userinfos: {
        connected: null//parseInt(localStorage.getItem("login")) || 0
    }
}

export default (state = initialState, action) => {
    switch (action.type){
        case "LOAD_USERINFOS":
            return {
                ...state,
                userinfos: action.data
            };

        case "USER_LOGOUT":
            return {
                userinfos: null
            };
    
        default: 
            return state
    }
}
