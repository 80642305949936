import React from "react"

import axios from "axios"
import { connect } from "react-redux"

import history from './history';

class AccountManager extends React.Component {
    constructor(props) {
        super(props);
    }

    static setConnected(data){
        localStorage.setItem("token", data);
        localStorage.setItem("login", 1);
  
        return true;
    }
    
    static disconnect(){
        localStorage.removeItem("token");
        localStorage.removeItem("userinfos");
        localStorage.removeItem("login");
  
        return true;
    }
}

const mapStateToProps = state => ({ userinfos: state.userinfos });
export default connect(mapStateToProps)(AccountManager);

var _token = null;

const getExpirationDate = (jwtToken) => {
    if (!jwtToken) {
        return null;
    }
  
    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
    return jwt && jwt.exp && jwt.exp * 1000 || null;
};

const isExpired = (exp) => {
    if (!exp) {
        return false;
    }
  
    return Date.now() > exp;
};

export const isConnected = async () => {
    if(localStorage.getItem("login") == false){
        return false;
    }
  
    if(!_token){
        return false;
    }
  
    return true;
}

export const setToken = (token) => {
    if (token) {
        localStorage.setItem("login", 1);
        localStorage.setItem('token', JSON.stringify(token));
        
        _token = token;
    } else {
        localStorage.removeItem("login");
        localStorage.removeItem('token');
    }
};

export const getToken = async () => {
    try {
        _token = JSON.parse(localStorage.getItem('token'));
    } catch (error) {
  
    }
  
    if (!_token) {
        localStorage.removeItem("token");
        localStorage.removeItem("userinfos");
        localStorage.removeItem("login");

        _token = null;

        history.push("/signin");
        return null;
    }
  
    if (isExpired(getExpirationDate(_token.token))) {
        await refreshToken();
    }
  
    return _token.token;
};

export const getUserInfos = async () => {
    try {
        _token = await getToken();
    } catch (error) {
  
    }
  
    if (!_token) {
        localStorage.removeItem("token");
        localStorage.removeItem("userinfos");
        localStorage.removeItem("login");

        _token = null;

        history.push("/signin");
        return null;
    }
  
    const userinfos = await getUserInfosFromToken(_token);
    return userinfos;
}

export const getUserInfosFromToken = async (_token) => {
    const header = { 
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + _token
        }
    }


    const updatedUserInformation = await axios.get(`${process.env.REACT_APP_API}/api/v1/account/get/private_infos`, header).then(res => {
        return res.data;
    }).catch(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("userinfos");
        localStorage.removeItem("login");

        _token = null;

        history.push("/signin");
        return null;
    });

    if(!updatedUserInformation){
        return;
    }

    return updatedUserInformation;
}

export const refreshToken = async () => {
    var credential = {
        refresh_token: _token.refresh_token
    }
  
    const header = { 
        headers: { 
            'Content-Type': 'application/json'
        } 
    }

    const updatedToken = await axios.post(`${process.env.REACT_APP_API}/api/v1/refresh_token`, credential, header).then(res => {
        return res.data;
    }).catch(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("userinfos");
        localStorage.removeItem("login");

        _token = null;

        history.push("/signin");
        return null;
    });

    if(!updatedToken){
        return;
    }

    setToken(updatedToken);
    return _token.token;
}