import React from 'react';
import PropTypes from 'prop-types';

const Input = props => {
  return (
    <input
      {...props} 
      className={props.className}
      readOnly={true}
    />
  );
};

Input.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default Input;
