import React, { Component } from 'react';

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import Pagination from "./components/Pagination";

import { getToken } from "./utilities/AccountManager";

import axios from "axios";
import dayjs from "dayjs";

import Modal from "react-modal";

import star_life_left from '../asserts/img/star_life-left.webp';
import star_life_right from '../asserts/img/star_life-right.webp';

const ModalReservationStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "transparent",
        border: "none",
        width: "100%",
        margin: "auto",
        overflow: "unset"
    },
    overlay : {
        backgroundColor: "rgba(172, 172, 172, 0.41)",
        zIndex: '5'
    }
};

class Reservations extends Component {
    state = {
        reservations: [],
        totalReservations: 0,
        offset: 0,
        limit: 20,
        currentPage: 1,
        cancel_reservation: false,
        cancel_reservation_data: {},
        cancel_reservation_call: false
    }

    async componentDidMount() {
        if (localStorage.getItem("login") == false || localStorage.getItem("token") == null) { 
            this.props.history.push("/signin");
        }

        if(localStorage.getItem("login") == true){
            await this.requestOnReservations();
        }
    }

    onChangeLimit = async (e) => {
        this.setState({ limit: e.target.value });
        await this.requestOnReservations(e.target.value);
    }

    refresh = async () => {
        await this.requestOnReservations();
    }

    requestOnReservations = async (limit = null) => {
        const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/api/v1/reservations/get/${this.state.offset || 0}`,
            params: {
                limit: (limit == null ? this.state.limit : limit) || 20
            },
            headers: { 'Authorization': `Bearer ${await getToken()}` }
        });
        
        const { reservations, totalCount } = response.data;

        this.setState({ 
            reservations: reservations,
            totalReservations: totalCount
        });
    }

    onCancelReservation = async (id) => {
        const reservation = (this.state.reservations).find((reservation) => reservation._id === id);

        if((dayjs(`${dayjs(reservation.date).format("DD-MM-YYYY")} ${reservation.time}`, "DD-MM-YYYY HH:mm")).isAfter(dayjs().add(1, "hour"))) {
            this.openCancelReservation(reservation);
        }else{
            this.openCancelReservation(reservation, true);
        }
    }

    confirmeCancelReservation = async () => {        
        const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/api/v1/reservations/delete/`,
            data: {
                id_reservation: this.state.cancel_reservation_data._id
            },
            headers: { 'Authorization': `Bearer ${await getToken()}` }
        });

        if(response.data.success) {
            const delete_reservation = (this.state.reservations).filter((reservation) => reservation._id !== this.state.cancel_reservation_data._id);
            
            this.setState({ reservations: delete_reservation, totalCount: this.state.totalReservations - 1 });
            this.openCancelReservation();
        }
    }

    openCancelReservation = (cancel_reservation_data, cancel_reservation_call = false) => {
        this.setState({ cancel_reservation: !this.state.cancel_reservation, cancel_reservation_data, cancel_reservation_call });
    }

    render() {
        const reservations = (this.state.reservations).map((reservation, index) => {
            return (
                <div className="flex jcsb" style={{ borderBottom: "1px solid var(--color-gray)", padding: "5px 0px" }} key={index}>
                    <div className="flex aic" style={{ padding: "10px 0px" }}>
                        <div style={ reservation.type === "vsl" ? { marginRight: "15px", marginTop: "auto" } : { margin: "15px 15px auto 0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.377 25" className="hw-25">
                                <path d="M29.618,17.188h-.759V11.909a2.378,2.378,0,0,0-.669-1.655L23.447,5.376a2.247,2.247,0,0,0-1.609-.688H19.745V2.344A2.312,2.312,0,0,0,17.467,0H2.278A2.312,2.312,0,0,0,0,2.344V17.969a2.312,2.312,0,0,0,2.278,2.344h.759A4.625,4.625,0,0,0,7.594,25a4.625,4.625,0,0,0,4.557-4.687h6.075a4.558,4.558,0,1,0,9.113,0h2.278a.773.773,0,0,0,.759-.781V17.969A.773.773,0,0,0,29.618,17.188ZM7.594,22.656a2.345,2.345,0,1,1,2.278-2.344A2.312,2.312,0,0,1,7.594,22.656Zm6.835-12.109a.386.386,0,0,1-.38.391H11.391v2.734a.386.386,0,0,1-.38.391H8.733a.386.386,0,0,1-.38-.391V10.938H5.7a.386.386,0,0,1-.38-.391V8.2a.386.386,0,0,1,.38-.391H8.354V5.078a.386.386,0,0,1,.38-.391h2.278a.386.386,0,0,1,.38.391V7.813h2.658a.386.386,0,0,1,.38.391Zm8.354,12.109a2.345,2.345,0,1,1,2.278-2.344A2.312,2.312,0,0,1,22.783,22.656ZM26.58,12.5H19.745V7.031h2.093l4.742,4.878Z"></path>
                            </svg>
                            { reservation.type === "vsl" ?
                                <div style={{ fontSize: "12px", textAlign: "center", fontFamily: "Arial Bold" }}>VSL</div>
                                :
                                null
                            }
                        </div>
                        <div className="pr" style={{ fontSize: "15px" }}>
                            <div className="flex pr">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20">
                                    <path d="M9.935,18.87A8.935,8.935,0,1,0,1,9.935,8.935,8.935,0,0,0,9.935,18.87Zm0-6.5A2.437,2.437,0,1,0,7.5,9.935,2.437,2.437,0,0,0,9.935,12.372Z" transform="translate(-1 -1)" fillRule="evenodd"></path>
                                </svg>
                                <span style={{ fontFamily: "Arial Bold", margin: "auto", marginLeft: "5px" }}>{reservation.startpoint}</span>
                            </div>
                            <div className="line" style={{ left: "7px", height: "25%" }}></div>
                            <div className="flex pr mt-10">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.87 17.87" className="hw-20">
                                    <path d="M19,2H2V19H19ZM13.05,7.95H7.95v5.1h5.1Z" transform="translate(-2 -2)" fillRule="evenodd"></path>
                                </svg>
                                <span style={{ marginLeft: "5px", fontFamily: "Arial Bold", marginTop: "auto" }}>{reservation.endpoint}</span>
                            </div>
                        </div>
                    </div>
                    <div className="grid aic">
                        <div style={ (dayjs(`${dayjs(reservation.date).format("DD-MM-YYYY")} ${reservation.time}`, "DD-MM-YYYY HH:mm")).isAfter(dayjs()) ? { background: "#00ab4c", padding: "4px 7px", borderRadius: "7px", color: "white", fontFamily: "Segoe UI SemiBold", fontSize: "13px" } : { background: "#e9e9ed", padding: "4px 7px", borderRadius: "7px", color: "var(--color-text-primary)", fontFamily: "Segoe UI SemiBold", fontSize: "13px" }}>{`${dayjs(reservation.date).format("DD-MM-YYYY")} à ${reservation.time}`}</div>
                        { (dayjs(`${dayjs(reservation.date).format("DD-MM-YYYY")} ${reservation.time}`, "DD-MM-YYYY HH:mm")).isAfter(dayjs()) ?
                                <button onClick={() => this.onCancelReservation(reservation._id)} style={{ color: "red", padding: "5px", fontFamily: "Segoe UI SemiBold", cursor: "pointer" }}>Annuler la course</button>
                            :
                            null
                        }
                    </div>
                </div>
            )
        })

        return (
            <>
                <HeaderComponent />
                <img className="starlife_backstage starlife_left" alt='starlife_backstage-left' src={star_life_left} style={{ marginTop: "80px" }} />
                <img className="starlife_backstage starlife_right"  alt='starlife_backstage-right' src={star_life_right} />

                <div className="faq_container">
                    <h1 className="faq_title">Reservations</h1>

                    <div className="faq_content" style={{ padding: "0px 10px" }}>
                        <div className="flex jcsb" style={{ fontFamily: "Roboto Bold", marginBottom: "15px" }}>
                            <div className="flex aic">
                                <span>Resultats ({this.state.totalReservations || 0})</span>
                                <svg className="hw-16" style={{ cursor: "pointer", marginLeft: "5px" }} onClick={this.refresh} viewBox="0 0 118.04 122.88">
                                    <path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z"/>
                                </svg>
                            </div>
                            <select style={{ fontSize: "14px", padding: "0px", width: "unset" }} value={this.state.limit} onChange={this.onChangeLimit}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                            </select>
                        </div>
                        { (this.state.reservations).length != 0 ?
                            <>
                                <div>
                                    {reservations}
                                </div>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={this.state.currentPage}
                                    totalCount={this.state.totalReservations}
                                    pageSize={this.state.limit}
                                    onPageChange={(page) => this.setState({ currentPage: page, offset: (page - 1) * this.state.limit }, () => this.requestOnReservations())}
                                />
                            </>
                            :
                            <p className="text-center">Aucune reservations</p>
                        }
                    </div>
                    <Modal isOpen={this.state.cancel_reservation} onRequestClose={this.openCancelReservation} style={ModalReservationStyles}>
                        <div className="modal-card nj abs-c">
                            <div className="pr">
                                <h2 className="mb-20" style={{ color: "var(--color-primary)", fontFamily: "Lemon Milk Pro Medium", paddingRight: "40px" }}>
                                    Voulez vous vraiment annuler cette course ?
                                </h2>
                                <button onClick={this.openCancelReservation} style={{ position: "absolute", left: "97%", transform: "translate(-50%, -50%)", top: "18px", padding: "7px", borderRadius: "13px", background: "#0000BB14", height: "40px", width: "40px", justifyContent: "center", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <svg version="1.1" viewBox="0 0 512 512" style={{ height: "17px",width: "17px",fill: "var(--color-primary)" }}>
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path>
                                    </svg>
                                </button>
                            </div>
                            <p className="text-center mb-20" style={{ fontFamily: "Bahnschrift", padding: "0px 10px", color: "red" }}>
                                Nous comprenons que parfois des changements de plans peuvent survenir. Avant de procéder à l'annulation de cette course, 
                                veuillez prendre un moment pour réfléchir. Soyez conscient(e) que cette action est irréversible, et une fois la course annulée, 
                                il ne sera pas possible de la restaurer.
                                <br /><br />
                                { 
                                    !this.state.cancel_reservation_call ?
                                        "Votre satisfaction est notre priorité, et nous sommes là pour vous assister. \
                                        Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter. \
                                        Merci de votre compréhension."
                                    :
                                        "Malheureusement, le départ de cette course est imminent, avec seulement une heure \
                                        restante. Pour annuler la réservation, veuillez nous contacter rapidement au \
                                        03 20 91 11 83."
                                }
                                
                            </p>
                            { 
                                !this.state.cancel_reservation_call ?
                                    <div className="flex jcc">
                                        <button className="search_button" style={{ marginRight: "20px", padding: "14px 0px", background: "#dd1414" }} onClick={this.confirmeCancelReservation}>Confirmer l'annulation</button>
                                        <button className="search_button" style={{ padding: "14px 0px", background: "var(--color-primary)" }} onClick={this.openCancelReservation}>Abandonner</button>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </Modal>
                </div>
                <FooterComponent />
            </>
        );
    }
}

export default Reservations;