import React, { Component } from 'react';

class RadioComponent extends Component {
    render() {
        const { types, selected, nameRadio, options, disabled, onHandleTypes } = this.props;

        return (
            <div className={ options ? `radio_container ${options}` : "radio_container" } value={selected} onChange={onHandleTypes}>
                {
                    types.map((item, index) => (
                        <>
                            <input 
                                id={item.id}
                                className="none"
                                type="radio" 
                                name={nameRadio}
                                value={item.id} 
                                checked={selected === item.id}
                            />
                            <label style={disabled == "disabled" ? { color: "#6d6d6d" } : {}}  for={item.id}>{item.label}</label>
                        </>
                    ))
                }
            </div>
        );
    }
}

export default RadioComponent;