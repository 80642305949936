import React, { Component } from 'react';

class SkillsComponents extends Component {
    render() {
        const { items } = this.props;

        return (
            <div className="items-experience">
                {items.map((item, index) => (
                    <div className="item-experience" key={index}>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                ))}
            </div>
        );
    }
}

export default SkillsComponents;
