import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import Input from '../elements/Input';
import CalendarContainer from '../elements/CalendarContainer';
import CalendarHeading from '../elements/CalendarHeading';
import SingleArrow from '../elements/SingleArrow';
import DateEditor from '../elements/DateEditor';
import CalendarBody from '../elements/CalendarBody';

import axios from 'axios';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

class ModernDatepicker extends Component {
    constructor(props) {
        super(props);
        this.isInstanceOfDate = props.date && Object.prototype.toString.call(props.date) === '[object Date]' && props.date instanceof Date && typeof props.date.getMonth === 'function';
        this.isMaxInstanceOfDate = props.maxDate && Object.prototype.toString.call(props.maxDate) === '[object Date]' && props.maxDate instanceof Date && typeof props.maxDate.getMonth === 'function';
        this.isMinInstanceOfDate = props.minDate && Object.prototype.toString.call(props.minDate) === '[object Date]' && props.minDate instanceof Date && typeof props.minDate.getMonth === 'function';
        this.state = {
            showContainer: false,
            allIntervalTimes: [
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00"
            ],
            setViewFor: 'date',
            textDate: '',
            hour: '',
            dateToEdit: props.date ? (this.isInstanceOfDate ? dayjs(props.date).format(props.format || 'DD-MM-YYYY') : dayjs(props.date, props.format || 'DD-MM-YYYY').format(props.format || 'DD-MM-YYYY')) : '',
            isValid: props.date ? (this.isInstanceOfDate ? dayjs(props.date).isValid() : dayjs(props.date, props.format || 'DD-MM-YYYY').isValid()) : true,
            isMaxValid: props.maxDate ? (this.isMaxInstanceOfDate ? dayjs(props.maxDate).isValid() : dayjs(props.maxDate, props.format || 'DD-MM-YYYY').isValid()) : true,
            isMinValid: props.minDate ? (this.isMinInstanceOfDate ? dayjs(props.minDate).isValid() : dayjs(props.minDate, props.format || 'DD-MM-YYYY').isValid()) : true,
            isAvailability: false,
            intervalTime: [],
        };
        this.state.yearBlock = dayjs(this.state.dateToEdit || dayjs().format(props.format || 'DD-MM-YYYY'), props.format || 'DD-MM-YYYY').get('year') - 8
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleHourChange = this.handleHourChange.bind(this);
        this.onChargeDataRender = this.onChargeDataRender.bind(this);
        this.modelSetInterfaceTimes = this.modelSetInterfaceTimes.bind(this);
        this.isValidTimes = this.isValidTimes.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown = (event) => {
        const { nextInput } = this.props;

        if(typeof nextInput === "function") {
            if (event.key === 'Enter') {
                event.preventDefault();
                
                nextInput();
            }
        }
    }

    componentDidMount() {
        this.onChargeDataRender();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type) {
            this.onChargeDataRender();
        }
    }

    onChargeDataRender = (date = this.state.dateToEdit) => {
        const { type, onChange, request } = this.props;

        if( request != null ? request : true ) {
            axios.post(`${process.env.REACT_APP_API}/api/v1/search_vehicule`, {
                type,
                date
            }).then((res) => {
                const { onHandleMinDate } = this.props
                const times = (res.data.preview).filter(element => !element.isReserved && this.isValidTimes([element.intervalTime])[0]).map(element => element.intervalTime);

                if(times.length === 0){
                    const newDate = dayjs(this.state.dateToEdit, "DD-MM-YYYY").add(1, 'day')
                    this.setState({
                        dateToEdit: newDate.format("DD-MM-YYYY"),
                        textDate: newDate.format("DD-MM-YYYY")
                    }, () => {
                        onHandleMinDate(newDate.valueOf());
                        this.onChargeDataRender();
                    })
                } else {
                    this.setState({
                        hour: this.modelSetInterfaceTimes(times)[0],
                        intervalTime: this.modelSetInterfaceTimes(times),
                    }, () => {
                        onChange({
                            date: this.state.dateToEdit,
                            hour: this.modelSetInterfaceTimes(times)[0]
                        });
                    })
                } 
            }).catch((e) => {
                this.setState({
                    hour: this.modelSetInterfaceTimes(this.props.intervalTime || this.state.allIntervalTimes)[0],
                    intervalTime: this.modelSetInterfaceTimes(this.props.intervalTime || this.state.allIntervalTimes),
                }, () => {
                    onChange({
                        date: this.state.dateToEdit,
                        hour: this.modelSetInterfaceTimes(this.props.intervalTime || this.state.allIntervalTimes)[0]
                    });
                })
            });
        } else {
            if(dayjs().format("YYYY-MM-DD") == dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD")) {
                this.setState({
                    dateToEdit: date,
                    textDate: date,
                    hour: (this.props.intervalTime || this.state.allIntervalTimes)[0],
                    intervalTime: this.props.intervalTime ||this.state.allIntervalTimes
                }, () => {
                    onChange({
                        date: date,
                        hour: (this.props.intervalTime || this.state.allIntervalTimes)[0],
                    });
                })
            } else {
                this.setState({
                    dateToEdit: date,
                    textDate: date,
                    hour: this.modelSetInterfaceTimes(this.props.intervalTime || this.state.allIntervalTimes)[0],
                    intervalTime: this.modelSetInterfaceTimes(this.props.intervalTime || this.state.allIntervalTimes)
                }, () => {
                    onChange({
                        date: date,
                        hour: this.modelSetInterfaceTimes(this.props.intervalTime || this.state.allIntervalTimes)[0],
                    });
                })
            }
        } 
    }

    modelSetInterfaceTimes = (times) => {
        var dateNow = new Date();
        const currentHour = dateNow.getHours();
        const currentMinutes = dateNow.getMinutes();

        const currentDate = dayjs().format('DD-MM-YYYY');
        const dateToEdit = dayjs(this.state.dateToEdit, 'DD-MM-YYYY').format('DD-MM-YYYY');

        const filteredTimes = times.filter(time => {
            const [hour, minutes] = time.split(":").map(Number);
            return hour > currentHour || (hour === currentHour && minutes > currentMinutes);
        });
    
        return (currentDate === dateToEdit) ? filteredTimes : times;
    };
    

    isValidTimes = (times) => {
        var dateNow = new Date();
        const currentHour = dateNow.getHours();
        const currentMinutes = dateNow.getMinutes();

        const currentDate = dayjs().format('DD-MM-YYYY');
        const dateToEdit = dayjs(this.state.dateToEdit, 'DD-MM-YYYY').format('DD-MM-YYYY');

        const filteredTimes = (times).map(time => {
            const [hour, minutes] = time.split(":").map(Number);
            return hour > currentHour || (hour === currentHour && minutes > currentMinutes);
        });
    
        return (currentDate === dateToEdit) ? filteredTimes : times;
    }

    checkDateInstance() {
        const isInstanceOfDate = this.props.date && Object.prototype.toString.call(this.props.date) === '[object Date]' && this.props.date instanceof Date && typeof this.props.date.getMonth === 'function',
            isMaxInstanceOfDate = this.props.maxDate && Object.prototype.toString.call(this.props.maxDate) === '[object Date]' && this.props.maxDate instanceof Date && typeof this.props.maxDate.getMonth === 'function',
            isMinInstanceOfDate = this.props.minDate && Object.prototype.toString.call(this.props.minDate) === '[object Date]' && this.props.minDate instanceof Date && typeof this.props.minDate.getMonth === 'function';
        return [isInstanceOfDate, isMaxInstanceOfDate, isMinInstanceOfDate];
    }

    handleDateChange(value, unit) {
        const { format, onChange, maxDate, minDate } = this.props;
        const { yearBlock } = this.state;
        const [ isMaxInstanceOfDate, isMinInstanceOfDate ] = this.checkDateInstance();
        const _date = dayjs(this.state.dateToEdit || dayjs().format(format || 'DD-MM-YYYY'), format || 'DD-MM-YYYY');
        const _minDate = isMinInstanceOfDate ? dayjs(minDate) : dayjs(minDate, format || 'DD-MM-YYYY');
        const _maxDate = isMaxInstanceOfDate ? dayjs(maxDate) : dayjs(maxDate, format || 'DD-MM-YYYY');
        const defaultDate = _date.isBetween(minDate ? _minDate.clone().subtract(1, 'day') : _date.clone().subtract(1, 'day'), maxDate ? _maxDate.clone().add(1, 'day') : _date.clone().add(1, 'day')) ? _date : maxDate && _maxDate.isSameOrAfter(_date) ? _date : minDate && _minDate.isSameOrBefore(_date) ? _minDate : maxDate ? _maxDate.clone().subtract(1, 'day') : minDate ? _minDate.clone().add(1, 'day') : _date;

        const date = dayjs(this.state.dateToEdit || defaultDate.format(format || 'DD-MM-YYYY'), format || 'DD-MM-YYYY').set(unit, value);
        const year = date.get('year');
        const newYearBlock = this.getNewYearBlock(yearBlock, year);
        const dateToEdit = date.format(format || 'DD-MM-YYYY');

        this.setState({
            dateToEdit,
            textDate: dateToEdit,
            yearBlock: newYearBlock
        }, () => {
            this.onChargeDataRender(dateToEdit);
            onChange({
                date: dateToEdit,
                hour: this.state.hour
            });
        });
    }

    handleHourChange(e) {
        e.preventDefault();
        const { onChange } = this.props;

        var hour = e.target.value;
        this.setState({
            hour
        }, () => {
            onChange({
                date: this.state.textDate,
                hour
            });
            var intervalId = setInterval(() => {
                this.setState({ showContainer: true })
            }, 5);

            setTimeout(function() {
                console.log("Stopping setInterval after 10 seconds");
                clearInterval(intervalId);
            }, 1000);
        });
    }

    addDate(value, unit) {
        const { format, onChange, maxDate, minDate } = this.props;
        const { dateToEdit } = this.state;
        const [isMaxInstanceOfDate, isMinInstanceOfDate] = this.checkDateInstance();
        const _date = dayjs(this.state.dateToEdit || dayjs().format(format || 'DD-MM-YYYY'), format || 'DD-MM-YYYY');
        const _minDate = isMinInstanceOfDate ? dayjs(minDate) : dayjs(minDate, format || 'DD-MM-YYYY');
        const _maxDate = isMaxInstanceOfDate ? dayjs(maxDate) : dayjs(maxDate, format || 'DD-MM-YYYY');
        const defaultDate = _date.isBetween(minDate ? _minDate.clone().subtract(1, 'day') : _date.clone().subtract(1, 'day'), maxDate ? _maxDate.clone().add(1, 'day') : _date.clone().add(1, 'day')) ? _date : maxDate && _maxDate.isSameOrAfter(_date) ? _date : minDate && _minDate.isSameOrBefore(_date) ? _minDate : maxDate ? _maxDate.clone().subtract(1, 'day') : minDate ? _minDate.clone().add(1, 'day') : _date;

        const date = dayjs(dateToEdit || defaultDate, format || 'DD-MM-YYYY').clone().add(value, unit);
        let newDateToEdit = date.format(format || 'DD-MM-YYYY');

        this.setState({
            dateToEdit: newDateToEdit,
            textDate: newDateToEdit
        }, () => {
            this.onChargeDataRender(newDateToEdit)
            onChange({
                date: newDateToEdit,
                hour: this.state.hour
            });
        });
    }
    subDate(value, unit) {
        const { format, onChange, maxDate, minDate } = this.props;
        const { dateToEdit } = this.state;
        const [ isMaxInstanceOfDate, isMinInstanceOfDate ] = this.checkDateInstance();
        const _date = dayjs(this.state.dateToEdit || dayjs().format(format || 'DD-MM-YYYY'), format || 'DD-MM-YYYY');
        const _minDate = isMinInstanceOfDate ? dayjs(minDate) : dayjs(minDate, format || 'DD-MM-YYYY');
        const _maxDate = isMaxInstanceOfDate ? dayjs(maxDate) : dayjs(maxDate, format || 'DD-MM-YYYY');
        const defaultDate = _date.isBetween(minDate ? _minDate.clone().subtract(1, 'day') : _date.clone().subtract(1, 'day'), maxDate ? _maxDate.clone().add(1, 'day') : _date.clone().add(1, 'day')) ? _date : maxDate && _maxDate.isSameOrAfter(_date) ? _date : minDate && _minDate.isSameOrBefore(_date) ? _minDate : maxDate ? _maxDate.clone().subtract(1, 'day') : minDate ? _minDate.clone().add(1, 'day') : _date;

        const date = dayjs(dateToEdit || defaultDate, format || 'DD-MM-YYYY').clone().subtract(value, unit);

        let newDateToEdit = date.format(format || 'DD-MM-YYYY');

        if (date < minDate) {
            newDateToEdit = dayjs(minDate).format(format || 'DD-MM-YYYY');
        }

        this.setState({
            dateToEdit: newDateToEdit,
            textDate: newDateToEdit
        }, () => {
            this.onChargeDataRender(newDateToEdit)
            onChange({
                date: newDateToEdit,
                hour: this.state.hour
            });
        });
    }

    getNewYearBlock(yearBlock, value) {
        let newYearBlock;
        let year = value;
        if (year < yearBlock) {
            newYearBlock = year - 15;
        } else if (year > (yearBlock + 15)) {
            newYearBlock = year;
        } else {
            newYearBlock = yearBlock;
        }
        return newYearBlock;
    }
    
    onBlur(e) {
        const currentTarget = e.currentTarget;
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                this.toggleCalendar(false);
            }
        }, 0);
    }

    toggleCalendar(bool, e) {
        const { date, format, onFocus, onBlur } = this.props;
        if (bool && onFocus) {
            onFocus(e);
        }
        else if (!bool && onBlur) {
            onBlur();
        }
        const [isInstanceOfDate] = this.checkDateInstance();
        const dateToEdit = bool ? (date ? (isInstanceOfDate ? dayjs(date).format(format || 'DD-MM-YYYY') : dayjs(date, format || 'DD-MM-YYYY').format(format || 'DD-MM-YYYY')) : '') : this.state.dateToEdit;

        this.setState({
            showContainer: bool,
            setViewFor: bool ? 'date' : this.state.setViewFor,
            dateToEdit,
            textDate: dateToEdit,
            isValid: date ? (isInstanceOfDate ? dayjs(date).isValid() : dayjs(date, format || 'DD-MM-YYYY').isValid()) : this.state.isValid
        });
    }

    openViewFor(setViewFor) {
        this.setState({
            setViewFor
        });
    }

    onDateEnter(e) {
        e.preventDefault();
        const { format, onChange, maxDate, minDate } = this.props;
        const { yearBlock } = this.state;
        const textDate = e.target.value;
        const [isMaxInstanceOfDate, isMinInstanceOfDate] = this.checkDateInstance();
        const _date = dayjs(textDate, format || 'DD-MM-YYYY');
        const _minDate = isMinInstanceOfDate ? dayjs(minDate) : dayjs(minDate, format || 'DD-MM-YYYY');
        const _maxDate = isMaxInstanceOfDate ? dayjs(maxDate) : dayjs(maxDate, format || 'DD-MM-YYYY');
        if (_date.isValid() && _date.isBetween(minDate ? _minDate.clone().subtract(1, 'day') : _date.clone().subtract(1, 'day'), maxDate ? _maxDate.clone().add(1, 'day') : _date.clone().add(1, 'day'))) {
            const date = dayjs(textDate, format || 'DD-MM-YYYY').format(format || 'DD-MM-YYYY');
            const year = dayjs(textDate, format || 'DD-MM-YYYY').get('year');
            const newYearBlock = this.getNewYearBlock(yearBlock, year);
            this.setState({ dateToEdit: date, yearBlock: newYearBlock }, () => {
                onChange({
                    date,
                    hour: this.state.hour
                });
            })
        }
        this.setState({ textDate })
    }

    checkAndReturnDate() {
        const { isValid, isMaxValid, isMinValid } = this.state;
        const { format, date, maxDate, minDate } = this.props;
        const [isInstanceOfDate, isMaxInstanceOfDate, isMinInstanceOfDate] = this.checkDateInstance();
        const currentDate = date ? (isInstanceOfDate ? dayjs(date) : dayjs(date, format || 'DD-MM-YYYY')) : '';
        const startDate = minDate ? (isMinInstanceOfDate ? dayjs(minDate) : dayjs(minDate, format || 'DD-MM-YYYY')) : null;
        const endDate = maxDate ? (isMaxInstanceOfDate ? dayjs(maxDate) : dayjs(maxDate, format || 'DD-MM-YYYY')) : null;

        if (!isValid || !isMaxValid || !isMinValid) {
            return 'Invalid date';
        }
        else if (startDate && endDate && !endDate.startOf('day').isAfter(startDate)) {
            return 'Invalid max/min date';
        }
        else if (currentDate && startDate && !startDate.startOf('day').isSameOrBefore(currentDate)) {
            return 'Invalid min date';
        }
        else if (currentDate && endDate && !endDate.startOf('day').isSameOrAfter(currentDate)) {
            return 'Invalid max date';
        }
        else if (date) {
            return isInstanceOfDate ? dayjs(date).format(format || 'DD-MM-YYYY') : dayjs(date, format || 'DD-MM-YYYY').format(format || 'DD-MM-YYYY');
        }
        else {
            return '';
        }
    }

    render() {
        const { showContainer, setViewFor, dateToEdit, isValid, yearBlock, textDate } = this.state;
        const { allowEdit, format, placeholder, options, id, icon, maxDate, minDate, lang, primaryColor = '#0000bb', secondaryColor = '#fbfbfb', primaryTextColor = 'black', secondaryTextColor = '#fbfbfb' } = this.props;
        const [ isMaxInstanceOfDate, isMinInstanceOfDate] = this.checkDateInstance();
        const _minDate = minDate ? (isMinInstanceOfDate ? dayjs(minDate).format('DD-MM-YYYY') : dayjs(minDate).format(format || 'DD-MM-YYYY')) : null;
        const _maxDate = maxDate ? (isMaxInstanceOfDate ? dayjs(maxDate).format('DD-MM-YYYY') : dayjs(maxDate).format(format || 'DD-MM-YYYY')) : null;
        const value = this.checkAndReturnDate();
        
        var date = new Date();

        const isNow = dayjs(this.state.dateToEdit, 'DD-MM-YYYY').format('MM-YYYY') === dayjs(date).format('MM-YYYY');
        const nextMonth = dayjs(this.state.dateToEdit, 'DD-MM-YYYY').add(1, 'month');
        const isLastMoNow = nextMonth.isAfter(dayjs(date).add(2, 'month'));

        return (
            <div onBlur={(e) => this.onBlur(e)} className={`form-input pr ${options || ""}`} style={{ fontFamily: "Segoe UI SemiBold" }}>
                {icon}
                <Input
                    id={id}
                    onChange={(e) => allowEdit && this.onDateEnter(e)}
                    onKeyDown={this.handleKeyDown}
                    onFocus={(e) => this.toggleCalendar(true, e)}
                    placeholder={placeholder}
                    type="text"
                    value={`${(allowEdit ? (isValid ? textDate : value) : value)} à ${this.state.hour}`}
                />
                {showContainer && isValid && (this.checkAndReturnDate() === '' || dayjs(this.checkAndReturnDate(), format || 'DD-MM-YYYY').isValid()) && <CalendarContainer secondaryColor={secondaryColor}>
                    <CalendarHeading secondaryColor={secondaryColor}>
                        <SingleArrow
                            left
                            onClick={() => isNow ? null : this.subDate(1, 'month')}
                            primaryTextColor={primaryTextColor}
                            secondaryColor={ isNow ? '#fff' : '#f4f4f4' }
                        />
                        <DateEditor
                            date={dateToEdit}
                            format={format}
                            lang={lang}
                            maxDate={_maxDate}
                            minDate={_minDate}
                            primaryTextColor={primaryTextColor}
                            secondaryColor={secondaryColor}
                            viewFor={setViewFor}
                        />
                        <SingleArrow
                            onClick={() => isLastMoNow ? null : this.addDate(1, 'month')}
                            primaryTextColor={primaryTextColor}
                            secondaryColor={ isLastMoNow ? 'fff' : '#f4f4f4' }
                        />
                    </CalendarHeading>
                    { this.state.intervalTime ? 
                        <div style={{ display: "flex", margin: "10px 20px 20px auto", justifyContent: "end" }}>
                            <div style={{ width: "25%" }}>
                                <select name="time" value={this.state.hour} onChange={this.handleHourChange} style={{ width: "100%", position: "relative", margin: "0px auto", display: "flex", padding: "10px", fontSize: "14px", backgroundColor: "var(--color-secondary)", borderRadius: "8px", border: "none", outline: 0 }}>
                                    { 
                                        (this.state.intervalTime).map((time, index) => {
                                            return <option value={time} key={index}>{time}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        : null
                    }
                    <CalendarBody
                        date={dateToEdit}
                        format={format}
                        maxDate={_maxDate}
                        minDate={_minDate}
                        onChange={(value, unit) => this.handleDateChange(value, unit)}
                        primaryColor={primaryColor}
                        primaryTextColor={primaryTextColor}
                        secondaryColor={secondaryColor}
                        secondaryTextColor={secondaryTextColor}
                        viewFor={setViewFor}
                        yearBlock={yearBlock}
                    />
                </CalendarContainer>}
            </div>
        );
    }
}

ModernDatepicker.propTypes = {
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    format: PropTypes.string,
    iconClass: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    id: PropTypes.string,
    label: PropTypes.string,
    labelClass: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    lang: PropTypes.string,
    maxDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    minDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    primaryColor: PropTypes.string,
    primaryTextColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    secondaryTextColor: PropTypes.string,
    showBorder: PropTypes.bool,
};

export default ModernDatepicker;