export const loadUserInfos = data => dispatch => {
    return dispatch({
        type: "LOAD_USERINFOS",
        data: data
    })
}
  
export const logout = data => dispatch => {
    return dispatch({
        type: "USER_LOGOUT"
    })
}
  