import styled from 'styled-components';
const CalendarContainer = styled.div.attrs({
  // we can define static props
  tabIndex: '1'
})`
  width : 100%;
  user-select: none;
  max-width : 325px;
  min-width: 315px;
  position : absolute;
  top : 100%;
  background-color : ${props => props.secondaryColor};
  border: solid 1px #f4f4f4;
  border-radius: 6px;
  &:focus{
      outline : none;
  }
  margin-top: 10px;
  z-index: 99999;
`;
export default CalendarContainer;
