import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class FooterComponent extends Component {
    render() {
        return (
            <footer>
                <span>COPYRIGHT© { process.env.REACT_APP_APP_NAME } 2023 - TOUS DROITS RÉSERVÉS</span>
                <span>
                    <div className="more" style={{ margin: "0px" }}>
                        <NavLink to="/legal/terms" style={{ color: "white" }}>
                            <span style={{ color: "white" }}>Confidentialité</span>
                        </NavLink>
                        <span className="mw-5">•</span>
                        <NavLink to="/legal/mentions" style={{ color: "white" }}>
                            <span style={{ color: "white" }}>Mentions légales</span>
                        </NavLink>
                    </div>
                </span>
            </footer>
        );
    }
}

export default FooterComponent;
