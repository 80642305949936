import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import InputIconComponent from './components/InputIconComponent';
import RadioComponent from './components/RadioComponent';
import axios from 'axios';

import star_life_left from '../asserts/img/star_life-left.webp';
import star_life_right from '../asserts/img/star_life-right.webp';

class SignUp extends Component {
    state = {
        genres: [
            {
                id: "MALE",
                label: "HOMME"
            },
            {
                id: "FEMALE",
                label: "FEMME"
            }
        ],
        genre: "MALE",
        firstname: "",
        lastname: "",
        birthdate: "",
        email: "",
        password: "",
        error_message: "",
        message: "",
        connecting: false,
        create_account: false
    }

    componentDidMount() {
        if (localStorage.getItem("login") == true) {
            this.props.history.push("/");
        } else {
            this.keyDownHandler = this.keyDownHandler.bind(this);
            document.addEventListener('keydown', this.keyDownHandler);
        }
    }

    componentWillUnmount() {
        if (localStorage.getItem("login") != true) {
            document.removeEventListener('keydown', this.keyDownHandler);
        }
    }

    onHandleTypes = (e) => {
        this.setState({ genre: e.target.value });
    }

    onCheckEmail(email) {
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (filter.test(email)) {
            return true
        } else {
            return false
        }
    }

    keyDownHandler(event) {
        if (event.key === 'Enter') {
            if(!this.state.create_account) {
                event.preventDefault();
                this.createAccount();
            }
        }
    }

    createAccount = async () => {
        this.setState({
            error_message: "",
            message: "",
            connecting: true,
            create_account: false
        });

        if (this.state.firstname !== "" && this.state.lastname !== "" && this.state.birthdate !== "" && this.onCheckEmail(this.state.email) && this.state.password !== "") {
            const credentials = {
                gender: this.state.genre,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                birthdate: this.state.birthdate,
                email: this.state.email,
                password: this.state.password
            };

            axios.post(`${process.env.REACT_APP_API}/api/v1/register`, credentials).then(async (res) => {
                if (res.data.success == 1) {
                    this.setState({
                        message: "Compte créé avec succès (Vérifie tes mails)",
                        connecting: false,
                        create_account: true
                    });
                } else if (res.data.success == 2) {
                    this.setState({
                        error_message: "Email déjà utilisé",
                        connecting: false
                    });
                } else if (res.data.success == 3) {
                    this.setState({
                        error_message: "Veuillez réessayer plus tard",
                        connecting: false
                    });
                } else if (res.data.success == 4) {
                    this.setState({
                        error_message: "Compte deja exitant",
                        connecting: false
                    });
                } else {
                    this.setState({
                        error_message: "Veuillez réessayer plus tard",
                        connecting: false
                    });
                }
            })
            .catch(() => {
                this.setState({
                    connecting: false,
                    error_message: "Veuillez réessayer plus tard",
                });
            });
        } else {
            this.setState({
                connecting: false,
                error_message: "Remplissez tous les champs",
            });
        }
    }

    render() {
        return (
            <>
                <HeaderComponent />
                <img className="starlife_backstage starlife_left" alt='starlife_backstage-left' src={star_life_left} style={{ marginTop: "80px" }} />
                <img className="starlife_backstage starlife_right"  alt='starlife_backstage-right' src={star_life_right} />

                <div className="connection_container">
                    <div className="signin-container">
                        <h1 className="signin_title mb-10">S'inscrire</h1>
                        <p className="signin_subtitle mb-20">Créer un compte sur <span style={{ color: "var(--color-primary)", fontFamily: "Arial Bold" }}>{process.env.REACT_APP_APP_NAME}</span></p>
                        <div>
                            {
                                this.state.error_message != "" ?
                                    <div style={{
                                        textAlign: "center",
                                        padding: "12px",
                                        background: "#ffb5b5",
                                        color: "red",
                                        fontFamily: "Arial",
                                        marginBottom: "10px"
                                    }}>
                                        {this.state.error_message}
                                    </div>
                                :
                                null
                            }
                            {
                                this.state.message != "" ?
                                    <div style={{
                                        textAlign: "center",
                                        padding: "12px",
                                        background: "#b5ffb5",
                                        color: "green",
                                        fontFamily: "Arial",
                                        marginBottom: "10px"
                                    }}>
                                        {this.state.message}
                                    </div>
                                :
                                null
                            }
                        </div>

                        { 
                            !this.state.create_account ?
                                <div>
                                    <div style={{ width: "min-content", margin: "auto" }}>
                                        <RadioComponent types={this.state.genres} selected={this.state.genre} nameRadio="genre" onHandleTypes={this.onHandleTypes} />
                                    </div>
                                    <div className="flex" style={{ gap: "10px" }}>
                                        <InputIconComponent placeholder="Prénom" type="firstname" options="mt-10 w-100p" onHandleChange={(e) => this.setState({ firstname: e.target.value })} />
                                        <InputIconComponent placeholder="Nom de famille" type="lastname" options="mt-10 w-100p" onHandleChange={(e) => this.setState({ lastname: e.target.value })} />
                                    </div>
                                    <InputIconComponent placeholder="Date de naissance" type="date" options="mt-10" maxDate="16" onHandleChange={(e) => this.setState({ birthdate: e.target.value })} icon={<svg xmlns="http://www.w3.org/2000/svg" className="hw-25" viewBox="0 0 46 46"><path d="M24,1A23,23,0,1,0,47,24,22.934,22.934,0,0,0,24,1ZM36.545,28.182H19.818V7.273h6.273V21.909H36.545Z" transform="translate(-1 -1)"></path></svg>} />
                                    <InputIconComponent placeholder="E-mail" type="email" icon={<svg xmlns="http://www.w3.org/2000/svg" className="hw-25" viewBox="0 0 34.875 34.875"><path d="M17.438.563A17.438,17.438,0,1,0,34.875,18,17.434,17.434,0,0,0,17.438.563Zm0,6.75A6.188,6.188,0,1,1,11.25,13.5,6.188,6.188,0,0,1,17.438,7.313Zm0,24.188a13.474,13.474,0,0,1-10.3-4.8,7.839,7.839,0,0,1,6.926-4.2,1.72,1.72,0,0,1,.5.077,9.309,9.309,0,0,0,2.876.485,9.274,9.274,0,0,0,2.876-.485,1.72,1.72,0,0,1,.5-.077,7.839,7.839,0,0,1,6.926,4.2A13.474,13.474,0,0,1,17.438,31.5Z" transform="translate(0 -0.563)" /></svg>} onHandleChange={(e) => this.setState({ email: e.target.value })} options="mt-10" />
                                    <InputIconComponent placeholder="Mot de passe" type="password" icon={<svg xmlns="http://www.w3.org/2000/svg" className="hw-25" viewBox="0 0 28.278 28.278"><path d="M30.849,7.712V7.069a3.213,3.213,0,0,0-6.427,0v.643A1.285,1.285,0,0,0,23.136,9v5.141a1.285,1.285,0,0,0,1.285,1.285h6.427a1.285,1.285,0,0,0,1.285-1.285V9A1.285,1.285,0,0,0,30.849,7.712Zm-1.028,0H25.45V7.069a2.185,2.185,0,0,1,4.37,0v.643ZM26.9,17.995a10.611,10.611,0,0,1,.1,1.285,10.231,10.231,0,0,1-2.706,6.935,2.554,2.554,0,0,0-2.436-1.793H20.566V20.566A1.289,1.289,0,0,0,19.28,19.28H11.568V16.71h2.571a1.289,1.289,0,0,0,1.285-1.285V12.854h2.571a2.57,2.57,0,0,0,2.571-2.571V7.018a12.854,12.854,0,1,0,9,12.262c0-.437-.026-.861-.064-1.285H26.9ZM15.424,29.473a10.273,10.273,0,0,1-9-10.193,10.063,10.063,0,0,1,.27-2.3l6.157,6.157v1.285a2.57,2.57,0,0,0,2.571,2.571v2.481Z" transform="translate(-3.856 -3.856)" /></svg>} onHandleChange={(e) => this.setState({ password: e.target.value })} options="mt-10" />
                                    <p className="mt-10 text-center" style={{ fontFamily: "Arial", color: 'gray', fontSize: "13px" }}>En vous inscrivant, vous acceptez nos conditions générales. Découvrez comment nous collectons, utilisons et partageons vos données en lisant notre <NavLink to="/legal/terms" style={{ textDecoration: "none", cursor: "pointer", color: "var(--color-primary)" }}>Politique de confidentialité</NavLink></p>
                                    <button className="search_button" style={this.state.connecting ? { fontSize: "17px", padding: "13.3px 0px" } : { fontSize: "17px" }} onClick={this.state.connecting ? null : this.createAccount}>{this.state.connecting ? <div className="loader"></div> : "S'inscrire"}</button>
                                </div>
                            :
                            <NavLink to="/signin" style={{ textDecoration: "none" }}>
                                <button className="search_button" style={{ fontSize: "17px" }}>Se connecter</button>
                            </NavLink>
                        }
                        <p className="create">Deja un compte ? <NavLink style={{ textDecoration: "none", color: "var(--color-primary)", fontFamily: "Arial Bold" }} to="/signin">Se connecter</NavLink></p>
                    </div>
                </div>
                <FooterComponent />
            </>
        );
    }
}

export default SignUp;