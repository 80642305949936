import React, { Component } from 'react';
import { Route, NavLink } from "react-router-dom";

import staroflife from '../../asserts/img/staroflife_45x45.webp';
import profile from '../../asserts/img/profile.webp';

import { connect } from "react-redux";
import { logout } from "../../redux/actions.js";
import AccountManager, { getToken } from "../utilities/AccountManager.jsx";

class HeaderComponent extends Component {
    constructor(props) {
        super(props);
    }
    
    state = {
        userinfos: {},
        profil_option: false,
        connected: false
    }

    async componentWillUnmount() {
        if (localStorage.getItem("login") == true && localStorage.getItem("token") != null) { 
            const token = await getToken();
                
            try {
                if(token) {
                    const userinfosToken = token.split(".")[1];

                    this.setState({ userinfos: JSON.parse(atob(userinfosToken)) });
                }
            } catch (error) {
                this.signout();
            }
        } else {
            this.signout();
        }
    }

    async componentDidMount() {
        try {
            if (localStorage.getItem("login") == true && localStorage.getItem("token") != null) { 
                this.setState({ connected: true });

                const token = await getToken();
                
                try {
                    if(token) {
                        const userinfosToken = token.split(".")[1];

                        this.setState({ userinfos: JSON.parse(atob(userinfosToken)) });
                    }
                } catch (error) {
                    this.signout();
                }
            } else {
                this.signout();
            }
        } catch (error) {
            this.signout();
        }
    }

    openPOptions = () => {
        var open = this.state.profil_option;
        if (open) {
            document.removeEventListener('click', this.openPOptions);
        } else {
            setTimeout(() => {
                document.addEventListener('click', this.openPOptions);
            }, 5);
        }

        this.setState({ profil_option: !open });
    }

    signout = () => {
        AccountManager.disconnect();
        this.props.dispatch(logout());
    }

    render() {
        const getRouteDefault = () => {
            if(this.state.connected == true) {
                if(Object.keys((this.state.userinfos)).length > 0) {
                    if((this.state.userinfos.roles).includes("EMPLOYEE") || (this.state.userinfos.roles).includes("BOSS")) {
                        return "/administration";
                    }
                }
            }

            return "/";
        }

        return (
            <header>
                <div className="flex">
                    <NavLink className="flex aic" to={getRouteDefault()} style={{ textDecoration: "none" }}>
                        <img className="hw-45" src={staroflife} alt="staroflife" loading="lazy"/>
                        <span className="app-name">{process.env.REACT_APP_APP_NAME}</span>
                    </NavLink>
                </div>
                {this.state.connected == true ?
                    <>
                        <div className="flex profile-container" onClick={this.openPOptions}>
                            <div className="profile-image">
                                <img src={profile} alt="profile_picture" />
                            </div>
                            <div>
                                <p>{this.state.userinfos.firstname}</p>
                                <p>{this.state.userinfos.lastname}</p>
                            </div>
                        </div>
                        {this.state.profil_option ?
                            <div className="p-option-header">
                                <ul>
                                    <NavLink to={getRouteDefault()} style={{ textDecoration: "none" }}>
                                        <li>
                                            <div className="flex">
                                                <div className="profile-image" style={{ height: "35px", width: "35px" }}>
                                                    <img src={profile} alt="profile_picture" />
                                                </div>
                                                <span className="mv-auto" style={{ color: "var(--color-primary)" }}>Mon profil</span>
                                            </div>
                                        </li>
                                    </NavLink>
                                    {(this.state.userinfos.roles).includes("EMPLOYEE") || (this.state.userinfos.roles).includes("BOSS") ?
                                        <NavLink to={"/administration"} style={{ textDecoration: "none" }}>
                                            <li>
                                                <div className="flex h100p">
                                                    <div className="mv-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.377 25" className="hw-25 flex aic">
                                                            <path d="M29.618,17.188h-.759V11.909a2.378,2.378,0,0,0-.669-1.655L23.447,5.376a2.247,2.247,0,0,0-1.609-.688H19.745V2.344A2.312,2.312,0,0,0,17.467,0H2.278A2.312,2.312,0,0,0,0,2.344V17.969a2.312,2.312,0,0,0,2.278,2.344h.759A4.625,4.625,0,0,0,7.594,25a4.625,4.625,0,0,0,4.557-4.687h6.075a4.558,4.558,0,1,0,9.113,0h2.278a.773.773,0,0,0,.759-.781V17.969A.773.773,0,0,0,29.618,17.188ZM7.594,22.656a2.345,2.345,0,1,1,2.278-2.344A2.312,2.312,0,0,1,7.594,22.656Zm6.835-12.109a.386.386,0,0,1-.38.391H11.391v2.734a.386.386,0,0,1-.38.391H8.733a.386.386,0,0,1-.38-.391V10.938H5.7a.386.386,0,0,1-.38-.391V8.2a.386.386,0,0,1,.38-.391H8.354V5.078a.386.386,0,0,1,.38-.391h2.278a.386.386,0,0,1,.38.391V7.813h2.658a.386.386,0,0,1,.38.391Zm8.354,12.109a2.345,2.345,0,1,1,2.278-2.344A2.312,2.312,0,0,1,22.783,22.656ZM26.58,12.5H19.745V7.031h2.093l4.742,4.878Z"></path>
                                                        </svg>
                                                    </div>
                                                    <span className="mv-auto ml-4">Reservations</span>
                                                </div>
                                            </li>
                                        </NavLink>
                                        :
                                        <NavLink to={"/reservations"} style={{ textDecoration: "none" }}>
                                            <li>
                                                <div className="flex">
                                                    <div className="mv-auto">
                                                        <svg class="hw-25 flex aic" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" strokeLinecap="round" strokeLinejoin="round">
                                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                                        </svg>
                                                    </div>

                                                    <span className="mv-auto ml-4">Reservations</span>
                                                </div>
                                            </li>
                                        </NavLink>
                                    }

                                    {(this.state.userinfos.roles).includes("BOSS") ?
                                        <NavLink to={"/administration/users"} style={{ textDecoration: "none" }}>
                                            <li>
                                                <div className="flex h100p">
                                                    <div className="mv-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="hw-25 flex aic">
                                                            <path opacity="1" d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                                                        </svg>
                                                    </div>
                                                    <span className="mv-auto ml-4">Utilisateurs</span>
                                                </div>
                                            </li>
                                        </NavLink>
                                        :
                                        null
                                    }

                                    {(this.state.userinfos.roles).includes("BOSS") ?
                                        <NavLink to={"/administration/reservation"} style={{ textDecoration: "none" }}>
                                            <li>
                                                <div className="flex h100p">
                                                    <div className="mv-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="hw-25 flex aic">
                                                            <path opacity="1" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V428.7c-2.7 1.1-5.4 2-8.2 2.7l-60.1 15c-3 .7-6 1.2-9 1.4c-.9 .1-1.8 .2-2.7 .2H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 381l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.8 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8h8.9c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7L384 203.6V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM549.8 139.7c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM311.9 321c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L512.1 262.7l-71-71L311.9 321z" />
                                                        </svg>
                                                    </div>
                                                    <span className="mv-auto ml-4">Créer une course</span>
                                                </div>
                                            </li>
                                        </NavLink>
                                        :
                                        null
                                    }
                                    <NavLink to={"/signin"} style={{ textDecoration: "none" }}>
                                        <li className="signout" onClick={this.signout}>
                                            <div className="flex h100p">
                                                <div className="mv-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" className="hw-25 flex aic">
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path d="M510.371,226.513c-1.088-2.603-2.645-4.971-4.629-6.955l-63.979-63.979c-8.341-8.32-21.824-8.32-30.165,0     c-8.341,8.341-8.341,21.845,0,30.165l27.584,27.584H320.013c-11.797,0-21.333,9.557-21.333,21.333s9.536,21.333,21.333,21.333     h119.168l-27.584,27.584c-8.341,8.341-8.341,21.845,0,30.165c4.16,4.181,9.621,6.251,15.083,6.251s10.923-2.069,15.083-6.251     l63.979-63.979c1.984-1.963,3.541-4.331,4.629-6.955C512.525,237.606,512.525,231.718,510.371,226.513z" />
                                                                    <path d="M362.68,298.667c-11.797,0-21.333,9.557-21.333,21.333v106.667h-85.333V85.333c0-9.408-6.187-17.728-15.211-20.437     l-74.091-22.229h174.635v106.667c0,11.776,9.536,21.333,21.333,21.333s21.333-9.557,21.333-21.333v-128     C384.013,9.557,374.477,0,362.68,0H21.347c-0.768,0-1.451,0.32-2.197,0.405c-1.003,0.107-1.92,0.277-2.88,0.512     c-2.24,0.576-4.267,1.451-6.165,2.645c-0.469,0.299-1.045,0.32-1.493,0.661C8.44,4.352,8.376,4.587,8.205,4.715     C5.88,6.549,3.939,8.789,2.531,11.456c-0.299,0.576-0.363,1.195-0.597,1.792c-0.683,1.621-1.429,3.2-1.685,4.992     c-0.107,0.64,0.085,1.237,0.064,1.856c-0.021,0.427-0.299,0.811-0.299,1.237V448c0,10.176,7.189,18.923,17.152,20.907     l213.333,42.667c1.387,0.299,2.795,0.427,4.181,0.427c4.885,0,9.685-1.685,13.525-4.843c4.928-4.053,7.808-10.091,7.808-16.491     v-21.333H362.68c11.797,0,21.333-9.557,21.333-21.333V320C384.013,308.224,374.477,298.667,362.68,298.667z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>

                                                <span className="mv-auto ml-4">Se déconnecter</span>
                                            </div>
                                        </li>
                                    </NavLink>
                                </ul>

                                <div className="more">
                                    <NavLink to="/legal/terms">
                                        <span>Confidentialité</span>
                                    </NavLink>
                                    <span className="mw-5">•</span>
                                    <NavLink to="/legal/mentions">
                                        <span>Mentions légales</span>
                                    </NavLink>
                                </div>
                            </div>
                            :
                            null
                        }
                    </>
                    :
                    <NavLink className="flex aic" to="/signin" style={{ textDecoration: "none" }}>
                        <button>
                            <svg className="hw-15 mr-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.452 13.814">
                                <g transform="translate(0.75 0.75)">
                                    <path d="M16.952,26.6V25.237A2.737,2.737,0,0,0,14.214,22.5H8.738A2.737,2.737,0,0,0,6,25.237V26.6" transform="translate(-6 -14.29)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path d="M17.476,7.237A2.738,2.738,0,1,1,14.738,4.5,2.737,2.737,0,0,1,17.476,7.237Z" transform="translate(-9.262 -4.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </g>
                            </svg>
                            <div className="grid ta-start">
                                <span className="ff-lemon fs-12">Se connecter</span>
                                <span className=" ff-arial fs-10">Gérer mes RDV</span>
                            </div>
                        </button>
                    </NavLink>
                }
            </header>
        );
    }
}

const mapStateToProps = state => ({ user: state.user });
export default connect(mapStateToProps)(HeaderComponent);
